import { EndpointResponse } from '@app/@types/api.types';
import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Drawer, Tab } from '@mui/material';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { RecipientData } from '../transfer.types';
import { TransferPanel } from './TransferPanel';
import {
  AccountType,
  FeeConfiguration,
  filterDestinationsBySelectedMethod,
  filterMethodsByTabValue,
  formatAmountString,
  NormalizedDestination,
  PaymentMethodType,
  preselectedMethodByTabValue,
  TabValue,
  WithdrawalState,
} from './utils';

const tabClasses = {
  root: 'p-0 normal-case items-start w-auto min-w-0 me-8 h-fit min-h-fit pb-3',
};

export const WithdrawFundsSidebarNew = ({
  open,
  reset,
  onCompletedTansfer,
}: {
  open: boolean;
  reset: () => void;
  onCompletedTansfer: () => void;
}) => {
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState>('initial');
  const [tabValue, setTabValue] = useState<TabValue>('external_transfer');
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] =
    useState<PaymentMethodType | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>('');
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(formatAmountString(value));
  };
  const [description, setDescription] = useState<string>('');
  const { data: transferMethodsData, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher, {
    onSuccess: (data) => {
      setSelectedPaymentMethodType(
        preselectedMethodByTabValue(selectedPaymentMethodType, data.data, tabValue),
      );
    },
  });

  const { data: externalDestinationsData, isLoading: loadingExternalDestinations } = useSWR<
    EndpointResponse<RecipientData[]>
  >({ url: `/treasury/recipients` }, apiGetFetcher);

  const normalizedExternalDestinations: NormalizedDestination[] = useMemo(() => {
    if (!externalDestinationsData?.data) return [];

    return externalDestinationsData.data.map((destination) => ({
      id: destination.id,
      recipientType: 'external',
      name: destination.billing_details.name,
      type: 'bank_account',
      alternateName: destination.display_name,
      lastFour: destination.payment_details.bank_account.mask,
      supportedNetworks: destination.payment_details.bank_account
        .supported_networks as PaymentMethodType[],
      brand: null,
      recipientName: destination.billing_details.name,
    }));
  }, [externalDestinationsData]);

  const { data: ownDestinationsData, isLoading: loadingOwnDestinations } = usePaymentMethodsQuery();

  const normalizedOwnDestinations: NormalizedDestination[] = useMemo(() => {
    if (!ownDestinationsData?.data) return [];

    return ownDestinationsData.data.map((destination) => {
      const type = destination.payment_method_detail.type as AccountType;

      if (type === 'bank_account') {
        destination = destination as BankAccountPaymentMethodCombined;
        return {
          id: destination.id,
          recipientType: 'own',
          alternateName: null,
          name: destination.payment_method_detail.name,
          type: 'bank_account',
          lastFour: destination.payment_method_detail.mask,
          supportedNetworks: destination.payment_method_detail
            .supported_networks as PaymentMethodType[],
          brand: null,
          recipientName: null,
        };
      } else {
        destination = destination as DebitCardPaymentMethodCombined;

        return {
          id: destination.id,
          recipientType: 'own',
          name: destination.payment_method_detail.description,
          alternateName: destination.payment_method_detail.expires,
          type: 'debit_card',
          lastFour: destination.payment_method_detail.last_four,
          brand: destination.payment_method_detail.brand,
          supportedNetworks: ['debit'],
          recipientName: null,
        };
      }
    });
  }, [ownDestinationsData]);

  const resetAndClose = () => {
    setAmount('');
    setDescription('');
    setSelectedDestination(null);
    setWithdrawalState('initial');
    setTabValue('external_transfer');
    setSelectedPaymentMethodType(
      preselectedMethodByTabValue(
        selectedPaymentMethodType,
        transferMethodsData?.data || [],
        tabValue,
      ),
    );
    reset();
  };

  return (
    <Drawer open={open} onClose={resetAndClose} anchor={'right'}>
      <div className="flex h-full w-screen flex-col gap-6 p-6 md:max-w-xl md:p-8">
        <div>
          <div className="w-fit">
            <Button
              size="small"
              color="secondary"
              onClick={resetAndClose}
              classes={{ root: '-mx-2 bg-white text-secondary hover:text-black' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-3 h-4 cursor-pointer self-center" />
              Back
            </Button>
          </div>
          <h3 className="mt-1 text-2xl font-semibold">Transfer Funds</h3>
        </div>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(_, value) => {
                setTabValue(value);
                setSelectedDestination(null);
                setSelectedPaymentMethodType(
                  preselectedMethodByTabValue(
                    selectedPaymentMethodType,
                    transferMethodsData?.data || [],
                    value,
                  ),
                );
              }}
              classes={{ root: 'min-h-fit h-fit' }}
            >
              <Tab
                label="Transfer to other account"
                value="external_transfer"
                classes={tabClasses}
              />
              <Tab label="Own transfer" value="own_transfer" classes={tabClasses} />
            </TabList>
          </Box>
          <TabPanel value="external_transfer" classes={{ root: 'p-0' }}>
            <TransferPanel
              selectedMethod={preselectedMethodByTabValue(
                selectedPaymentMethodType,
                transferMethodsData?.data || [],
                tabValue,
              )}
              selectedDestination={selectedDestination}
              availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], tabValue)}
              availableDestinations={normalizedExternalDestinations}
              loadingMethods={loadingMethods}
              onMethodSelected={setSelectedPaymentMethodType}
              onDestinationSelected={setSelectedDestination}
              loadingDestinations={loadingExternalDestinations}
              description={description}
              setDescription={setDescription}
              amount={amount}
              handleAmountChange={handleAmountChange}
              withdrawalState={withdrawalState}
              setWithdrawalState={setWithdrawalState}
              reset={resetAndClose}
              onCompletedTansfer={onCompletedTansfer}
            />
          </TabPanel>
          <TabPanel value="own_transfer" classes={{ root: 'p-0' }}>
            <TransferPanel
              selectedMethod={selectedPaymentMethodType}
              selectedDestination={selectedDestination}
              availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], tabValue)}
              availableDestinations={filterDestinationsBySelectedMethod(
                normalizedOwnDestinations,
                selectedPaymentMethodType,
              )}
              loadingMethods={loadingMethods}
              onMethodSelected={(value) => {
                const filteredDestinations = filterDestinationsBySelectedMethod(
                  normalizedOwnDestinations,
                  value,
                );
                if (
                  !filteredDestinations.some(
                    (destination) => destination.id === selectedDestination,
                  )
                ) {
                  setSelectedDestination(null);
                }
                setSelectedPaymentMethodType(value);
              }}
              onDestinationSelected={setSelectedDestination}
              loadingDestinations={loadingOwnDestinations}
              description={description}
              setDescription={setDescription}
              amount={amount}
              handleAmountChange={handleAmountChange}
              withdrawalState={withdrawalState}
              setWithdrawalState={setWithdrawalState}
              reset={resetAndClose}
              onCompletedTansfer={onCompletedTansfer}
            />
          </TabPanel>
        </TabContext>
      </div>
    </Drawer>
  );
};
