import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { ProductSubscriptionResponse } from '@app/@types/subscriptions.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import Header from '@app/components/Navigation/Header';
import { Spacer } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { usePayrollUpsellMarketing } from '@app/hooks/payroll/usePayrollUpsellMarketing';
import {
  PAYMENT_METHOD_QUERY_KEY,
  usePaymentMethodsQuery,
} from '@app/hooks/query/usePaymentMethodsQuery';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { sumPayrollPayment } from '@app/utils/payroll-itemization';
import axios from 'axios';
import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { mutate } from 'swr';
import { mapBankAndDebitCardData } from '../../components/PaymentMethods/PaymentMethodUtils';
import AddDriver from './AddDriver';
import PayrollFeeBanner from './Banners/PayrollFeeBanner';
import PayrollSuspendedBanner from './Banners/PayrollSuspendedBanner';
import PayDriverModal from './Modals/PayDriverModal';
import { DesktopItemizationStep } from './PaymentFlow/DesktopItemizationStep';
import { CalculationErrors, PaymentCalculation } from './PaymentFlow/PaymentCalculation';
import PayrollBenefits from './PayrollBenefits';
import PayrollMonetizationBenefits from './PayrollMonetizationBenefits';
import PayrollPaymentSource from './PayrollPaymentSource';
import PayrollSubscription from './PayrollSubscription';
import PayrollTabs from './PayrollTabs';
import DriverSidebar from './Table/DriverSidebar';
import PayrollDriversTable from './Table/PayrollDriversTable';
import Welcome from './Welcome';
import PayrollContext, { useInitialPayrollProviderState } from './payroll-context';

export interface PaymentSourceProps {
  paymentSourceDebit: DebitCardPaymentMethodCombined | null;
  setPaymentSourceDebit: (card: DebitCardPaymentMethodCombined | null) => void;
  paymentSourceAch: BankAccountPaymentMethodCombined | null;
  setPaymentSourceAch: (card: BankAccountPaymentMethodCombined | null) => void;
  isPrepaid: boolean;
}
export default function Payroll({ isPrepaid }: { isPrepaid: boolean }): ReactElement {
  const payrollInitialValues = useInitialPayrollProviderState();
  const [paymentSourceDebit, setPaymentSourceDebit] =
    useState<DebitCardPaymentMethodCombined | null>(null);
  const [paymentSourceAch, setPaymentSourceAch] = useState<BankAccountPaymentMethodCombined | null>(
    null,
  );

  return (
    <>
      <PayrollContext.Provider value={payrollInitialValues}>
        <PayrollUIWithData
          paymentSourceDebit={paymentSourceDebit}
          setPaymentSourceDebit={setPaymentSourceDebit}
          paymentSourceAch={paymentSourceAch}
          setPaymentSourceAch={setPaymentSourceAch}
          isPrepaid={isPrepaid}
        />
      </PayrollContext.Provider>
    </>
  );
}

const isFreeTrialExpiring = (subscription: ProductSubscriptionResponse | null) => {
  if (!subscription || subscription.data.attributes.status !== 'trialing') {
    return false;
  }
  // Billing period is of format Sep 06 - Oct 06, 2022
  const billing_period = subscription.data.attributes.details.billing_period;
  const billing_end_date = billing_period.split('-')[1];
  return dayjs().isAfter(dayjs(billing_end_date).subtract(7, 'day'));
};

const PayrollUIWithData = (props: PaymentSourceProps) => {
  const { paymentSourceDebit, setPaymentSourceDebit, paymentSourceAch, setPaymentSourceAch } =
    props;
  const [selectedDriverId, setSelectedDriverId] = useState<string | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { driverData, driverState, driverStateIds, updateDriverInState, loaded } =
    useContext(PayrollContext);
  const { fetch: fetchDriverData, error: networkError, loading } = driverData;
  const [payrollError, setPayrollError] = useState(networkError);
  const [payNowOrCalculate, setPayNowOrCalculate] = useState<'Pay now' | 'Calculate' | null>(null);
  const [amountToPayDriver, setAmountToPayDriver] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState<PaymentCalculation>({
    calculationMethod: payNowOrCalculate === 'Pay now' ? null : 'Flat Amount Payroll',
    amount_cents: amountToPayDriver,
    description: '',
    itemizations: [],
  });
  const [calculationErrors, setCalculationErrors] = useState<{
    [key in CalculationErrors]: string | null;
  }>({ gross_total_limit: null, load_rate_range_invalid: null, gross_total_below_zero: null });
  const [descriptionConfirmed, setDescriptionConfirmed] = useState(false);

  const hasDrivers = driverStateIds.length > 0;
  const driverToShow = selectedDriverId
    ? driverState[selectedDriverId]
    : ({} as FlattenedPayrollData);
  const noPaymentSource = !paymentSourceDebit && !paymentSourceAch;
  const { isSmallScreen } = useWindowWidth();
  const [MONETIZATION_ENABLED] = useFeatureFlags('payroll_new_customer_monetization');
  const [subscriptionPlan, setSubscriptionPlan] = useState<ProductSubscriptionResponse | null>(
    null,
  );
  const [cards, setCards] = useState<DebitCardPaymentMethodCombined[]>([]);
  const [bankAccounts] = useState<BankAccountPaymentMethodCombined[]>([]);
  const {
    isLoading: isLoadingPaymentMethods,
    data: paymentMethodsResponse,
    // refresh: refreshPaymentMethods,
  } = usePaymentMethodsQuery();

  useEffect(() => {
    fetchDriverData();
  }, [fetchDriverData]);

  useEffect(() => {
    axios.get('/subscriptions').then((res: { data: ProductSubscriptionResponse[] }) => {
      const subscriptions = deserialize(res.data).data as ProductSubscriptionResponse[];
      const plan = subscriptions.filter(
        (subscription) => subscription.data.attributes.product === 'payroll',
      );
      setSubscriptionPlan(plan.length === 0 ? null : plan[0]);
    });
  }, []);

  const isPaymentMethodsInitialized = useRef(false);

  useEffect(() => {
    if (
      isLoadingPaymentMethods ||
      paymentMethodsResponse == null ||
      isPaymentMethodsInitialized.current
    ) {
      return;
    }
    isPaymentMethodsInitialized.current = true;
    const paymentMethods = mapBankAndDebitCardData(paymentMethodsResponse.data || []);
    const { combinedPaymentMethods } = paymentMethods;
    const { debitCards: paymentCards } = combinedPaymentMethods;

    setCards(paymentCards ?? []);

    if (paymentCards && paymentCards.length > 0) {
      setPaymentSourceDebit(paymentCards[0]);
    }
  }, [isLoadingPaymentMethods, paymentMethodsResponse, setPaymentSourceDebit, setPaymentSourceAch]);

  const [shouldShowBenefits, handleBenefitsClick] = usePayrollUpsellMarketing(
    'getStartedWithPayrollClicked',
    { dataHandledOutsideOfHook: true, data: driverState, dataLoaded: loaded },
  );
  const { hideDismissableBanners } = useThemeConfiguration();

  const resetPaymentDetails = () => {
    setDescriptionConfirmed(false);
    setPaymentDetails({
      calculationMethod: 'Flat Amount Payroll',
      amount_cents: 0,
      description: '',
      itemizations: [],
    });
    setAmountToPayDriver(0);
    setPayNowOrCalculate(null);
    setCalculationErrors({
      gross_total_limit: null,
      load_rate_range_invalid: null,
      gross_total_below_zero: null,
    });
  };

  if (loading) {
    return (
      <div data-testid="loading">
        <PageContentWrapper
          header={<Header title="Driver Pay" />}
          pageTabs={<PayrollTabs tab="/payroll/overview" />}
        >
          <div>
            <Skeleton />
            <Spacer />
            <Skeleton />
            <Spacer />
            <Skeleton />
          </div>
        </PageContentWrapper>
      </div>
    );
  }

  if (shouldShowBenefits && !hideDismissableBanners) {
    return (
      <PageContentWrapper
        header={<Header title="" />}
        pageTabs={<PayrollTabs tab="/payroll/overview" />}
      >
        {MONETIZATION_ENABLED ? (
          <PayrollMonetizationBenefits onClick={handleBenefitsClick} />
        ) : (
          <PayrollBenefits onClick={handleBenefitsClick} />
        )}
      </PageContentWrapper>
    );
  }

  if (isFreeTrialExpiring(subscriptionPlan)) {
    return (
      <PageContentWrapper
        header={<Header title="" />}
        pageTabs={<PayrollTabs tab="/payroll/overview" />}
      >
        <PayrollSubscription />
      </PageContentWrapper>
    );
  }

  if (payNowOrCalculate === 'Calculate' && !descriptionConfirmed && !isSmallScreen) {
    const total = sumPayrollPayment(paymentDetails);
    const anyCalculationErrors =
      Object.values(calculationErrors).filter((message) => message !== null).length > 0;
    const humanReadableError = Object.values(calculationErrors)
      .filter((message): message is string => message !== null)
      .reduce((errorMessage, curError) => `${curError}. ${errorMessage}`, '');

    return (
      <DesktopItemizationStep
        driver={driverToShow}
        takeStep={(step) => {
          if (step === 'continue') {
            setDescriptionConfirmed(true);
          } else {
            resetPaymentDetails();
          }
        }}
        isContinueButtonDisabled={noPaymentSource || total <= 0 || anyCalculationErrors}
        humanReadableError={humanReadableError}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        setCalculationErrors={setCalculationErrors}
      />
    );
  }

  return (
    <PageContentWrapper
      header={<Header title="Driver Pay" />}
      pageTabs={<PayrollTabs tab="/payroll/overview" />}
    >
      <Welcome paymentSource={paymentSourceDebit} />
      <PayrollSuspendedBanner />
      <PayrollFeeBanner />
      {hasDrivers && (
        <div className="flex flex-col justify-between">
          <PayrollPaymentSource
            {...props}
            loading={isLoadingPaymentMethods}
            cards={cards}
            bankAccounts={bankAccounts}
            refreshPaymentMethods={() => {
              mutate(PAYMENT_METHOD_QUERY_KEY);
            }}
            isPrepaid={props.isPrepaid}
          />
          <div className="hidden md:mt-5 md:flex md:justify-end">
            <AddDriver shouldHideOnMobile />
          </div>
        </div>
      )}
      <div>
        {payrollError && <ErrorNotification error={payrollError} />}
        <PayrollDriversTable
          hasDrivers={hasDrivers}
          driverStateIds={driverStateIds}
          driverState={driverState}
          setSelectedDriverId={(driverId: string) => {
            setSelectedDriverId(driverId);
            setSidebarOpen(true);
          }}
        />
        {sidebarOpen && (
          <DriverSidebar
            noPaymentSource={noPaymentSource}
            driver={driverToShow}
            open={sidebarOpen}
            toggle={() => {
              setSelectedDriverId(null);
              setSidebarOpen(false);
            }}
            updateDriverInState={updateDriverInState}
            setIsPayingDriver={(option) => {
              setPayNowOrCalculate(option);
              setSidebarOpen(false);
            }}
            amountToPayDriver={amountToPayDriver}
            setAmountToPayDriver={(amount) => {
              setAmountToPayDriver(amount);
              setPaymentDetails((details) => {
                return { ...details, amount_cents: amount };
              });
            }}
          />
        )}
        {payNowOrCalculate && (
          <PayDriverModal
            payNowOrCalculate={payNowOrCalculate}
            amountToPay={amountToPayDriver}
            driver={driverToShow}
            reset={resetPaymentDetails}
            back={() => {
              setSidebarOpen(true);
            }}
            updateDriverInState={updateDriverInState}
            paymentSourceDebit={paymentSourceDebit}
            paymentSourceAch={paymentSourceAch}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            calculationErrors={calculationErrors}
            setCalculationErrors={setCalculationErrors}
            calculationDescriptionConfirmed={descriptionConfirmed}
            setPayrollError={setPayrollError}
          />
        )}
      </div>
    </PageContentWrapper>
  );
};
