import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { AddFundsModalDataWrapper } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import { apiGetFetcher, getFetcher } from '@app/utils/data/fetchers';
import {
  useHasCompletedOnboarding,
  useShouldBeInOnboarding,
} from '@app/utils/onboarding/useHasCompletedOnboarding';
import useWelcomePopup from '@app/utils/onboarding/useWelcomePopup';
import Modal, {
  ModalBodyContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeaderNEW,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import PayWithAtoBModal from '../Modals/OnboardingModals/PayWithAtoBModal';
import UberFreightFuelFinderUpsellModal from '../Modals/OnboardingModals/UberFreightFuelFinderUpsellModal';
import OnboardingStepper from '../Onboarding/OnboardingStepper';
import PrepaidOnboardingFlow from '../Prepaid/PrepaidOnboardingFlow';
import { SetupPromotionsModal } from '../Prepaid/SetupActivation/SetupPromotionsModal';
import AppInfoPopup from '../popups/components/AppInfoPopup';
import useIsDeviceAppEligible from '../popups/components/useIsDeviceAppEligible';

export default function PrepaidOnboarding(): ReactElement | null {
  const [deviceType] = useIsDeviceAppEligible();
  const customer = useCustomer();
  const navigate = useNavigate();
  const [isUberFreightFuelFinderUpsellModalOpen, setIsUberFreightFuelFinderUpsellModalOpen] =
    useState(false);
  const [isPayWithAtoBModalOpen, setIsPayWithAtoBModalOpen] = useState(false);
  const [showSetupFeeModal, setShowSetupFeeModal] = useState(false);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const [isNewOnboardingEnabled] = useFeatureFlags('new_onboarding_enabled');
  const [showAppPopup, setShowAppPopup] = useState(false);
  const [showActivationFeeReminder, setShowActivationFeeReminder] = useState(false);

  // Preload these keys to ensure SetupPromotionsModal shows up immediately if needed.
  // You need tin include the fetcher so the data is populated in the SWR map
  // properly (especially for JSON API transforms)
  useSWR(
    {
      url: '/treasury/account_promotions?account_setup_fee=true',
    },
    getFetcher,
  );
  useSWR(
    {
      url: '/treasury/account_promotions/accepted_promotions?account_setup_fee=true',
    },
    apiGetFetcher,
  );

  const [mustPayPreshipmentActivationFee, VIRTUAL_CARDS_PILOT, FLEX_MIGRATION] = useFeatureFlags(
    'unlimited_preshipment_activation_fee',
    'virtual_cards_pilot',
    'ul_to_flex_promo_v2',
  );

  const { partnerName } = useChannelPartner();
  const isUFCusomter = useIsUberfreight();

  const [shouldShow, welcomePopup] = useWelcomePopup(() => {
    if (mustPayPreshipmentActivationFee) {
      setShowSetupFeeModal(true);
      return;
    }
    if (!isUFCusomter) {
      setShowAppPopup(true);
    }
  });

  const shouldBeInOnboarding = useShouldBeInOnboarding();
  const hasCompletedOnboarding = useHasCompletedOnboarding();
  const customerOnboardingEvents = useCustomerOnboardingEvents();

  const allowedToFund =
    !shouldBeInOnboarding ||
    !mustPayPreshipmentActivationFee ||
    customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE);

  const stepGroups = !isUFCusomter
    ? [
        mustPayPreshipmentActivationFee
          ? [
              {
                title: `Get your ${partnerName} Cards Shipped`,
                onClick: () => {
                  setShowSetupFeeModal(true);
                },
                customerOnboardingEvent: CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE,
              },
              {
                title: 'View discounts in Fuel Map',
                onClick: () => {
                  createCustomerOnboardingEvent({
                    customer_onboarding_event: {
                      name: CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
                    },
                  });
                  navigate('/fuel-map');
                },
                customerOnboardingEvent:
                  CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
              },
            ]
          : [
              {
                title: 'Add funds to your Wallet',
                onClick: () => {
                  setShowAddFunds(true);
                },
                customerOnboardingEvent: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS,
              },
            ],
        [
          mustPayPreshipmentActivationFee
            ? {
                title: 'Add funds to your Wallet',
                onClick: () => {
                  if (!allowedToFund) {
                    setShowActivationFeeReminder(true);
                  } else {
                    setShowAddFunds(true);
                  }
                },
                customerOnboardingEvent: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS,
              }
            : {
                title: 'View discounts in Fuel Map',
                onClick: () => {
                  createCustomerOnboardingEvent({
                    customer_onboarding_event: {
                      name: CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
                    },
                  });
                  navigate('/fuel-map');
                },
                customerOnboardingEvent:
                  CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
              },
          {
            title: `Pay with the ${partnerName} Card`,
            onClick: () => {
              setIsPayWithAtoBModalOpen(true);
            },
            customerOnboardingEvent: CustomerOnboardingEventName.FIRST_TRANSACTION_SETTLED,
          },
        ],
      ]
    : [
        [
          {
            title: 'Add funds to your Uber Freight Card Wallet',
            onClick: () => {
              setShowAddFunds(true);
            },
            customerOnboardingEvent: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS,
          },
        ],
        [
          {
            title: 'View discounts in the Fuel Finder',
            onClick: () => {
              setIsUberFreightFuelFinderUpsellModalOpen(true);
            },
            customerOnboardingEvent:
              CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
          },
          {
            title: 'Pay with the Uber Freight Card',
            onClick: () => {
              setIsPayWithAtoBModalOpen(true);
            },
            customerOnboardingEvent: CustomerOnboardingEventName.FIRST_TRANSACTION_SETTLED,
          },
        ],
      ];

  return (
    <>
      {shouldShow && welcomePopup}
      {(showSetupFeeModal || !shouldBeInOnboarding) &&
        !FLEX_MIGRATION &&
        !VIRTUAL_CARDS_PILOT &&
        mustPayPreshipmentActivationFee && (
          <SetupPromotionsModal customer={customer} onClose={() => setShowSetupFeeModal(false)} />
        )}
      <OnboardingStepper
        stepGroups={stepGroups}
        completedPartnerText={isUFCusomter ? 'Uber Freight app and card' : 'AtoB app'}
      />
      {showAppPopup && (
        <AppInfoPopup deviceType={deviceType} isOpen={showAppPopup} setIsOpen={setShowAppPopup} />
      )}
      <Modal
        open={showActivationFeeReminder}
        toggle={() => setShowActivationFeeReminder(false)}
        noFullScreen
      >
        <ModalHeaderNEW
          title="Before you do..."
          closeButton={<ModalCloseButton onClick={() => setShowActivationFeeReminder(false)} />}
        />
        <ModalBodyContent>
          Get your cards shipped by paying a one-time account setup fee.
        </ModalBodyContent>
        <ModalFooter>
          <Button
            className="flex-1"
            size="medium"
            onClick={() => {
              setShowSetupFeeModal(true);
              setShowActivationFeeReminder(false);
            }}
          >
            Get Your Cards Shipped
          </Button>
        </ModalFooter>
      </Modal>
      <PayWithAtoBModal
        open={isPayWithAtoBModalOpen}
        onClose={() => {
          setIsPayWithAtoBModalOpen(false);
        }}
      />
      <UberFreightFuelFinderUpsellModal
        open={isUberFreightFuelFinderUpsellModalOpen}
        onClose={() => {
          setIsUberFreightFuelFinderUpsellModalOpen(false);
        }}
      />
      <AddFundsModalDataWrapper
        customerCompanyName={customer.company_name}
        customerCompanyAddress={customer.company_address}
        onAddFunds={() => {
          window.location.href = '/';
        }}
        modalActive={showAddFunds}
        setModalActive={() => setShowAddFunds(false)}
        minimumDepositAmountCents={customer?.treasury?.minimum_deposit_amount?.cents}
        initialAmount={customer?.treasury?.minimum_deposit_amount?.cents}
        scenario={'add_funds'}
      />
      {/*
        The PrepaidOnboardingFlow has onboarding flows around Flex to Unlimited,
        as well as account re-activation. We will hide it until the customer
        has gone through the onboarding flow and then let it handle all other flows as needed
      */}
      {(hasCompletedOnboarding || !isNewOnboardingEnabled) && <PrepaidOnboardingFlow />}
    </>
  );
}
