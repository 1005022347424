import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const CreditLimitIncreaseRequestSuccess = ({
  closeModal,
}: {
  closeModal: () => void;
}): ReactElement => {
  return (
    <>
      <ModalHeader title="" toggle={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-16 text-green-600 md:mt-2">
            <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
          </div>
          <h1 className="mb-1 text-center text-2xl font-bold">Request Pending</h1>
          <p className="text-md my-8 mb-2 w-3/4 text-center">
            Thank you for requesting a credit limit increase. Your request is under review;
            you&apos;ll receive an email once we have made a decision.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" size="small" onClick={closeModal}>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};
