import {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export interface UnlimitedToFlexMigrationConfirmOfferProps {
  creditLimit: number;
  activationFee: number;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationConfirmOffer = ({
  creditLimit,
  activationFee,
  onNext,
  onBack,
  onClose,
}: UnlimitedToFlexMigrationConfirmOfferProps): ReactElement | null => {
  return (
    <>
      <ModalHeader title="Review Details" toggle={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-sm text-gray-700">
          <div className="my-1">
            You will be upgraded to AtoB Flex once the ${activationFee} fee payment settles (usually
            within 4 business days).
          </div>
          <div className="my-1">
            A $3 per active card, per month fee will also be billed monthly.
          </div>
          <div className="my-1">
            A credit enablement surcharge of ${0.02 * creditLimit} per week (2% of weekly credit
            line) applies for the first two months.
          </div>

          <div className="my-4 text-base font-bold">Your Offer:</div>

          <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
            <div className="w-full p-3">
              <div className="flex items-center md:flex-col md:items-start">
                <h3 className="mr-2 text-4xl font-semibold">${creditLimit}</h3>
                <div className="mt-2 text-sm font-bold text-black">
                  Weekly credit line. Billed weekly.
                </div>
              </div>
            </div>
            <div className="ml-4 flex flex-col items-start p-2">
              <ul className="list-disc p-2 pt-0 text-sm">
                <li>No credit checks or personal guarantees</li>
                <li>Accepted at stations nationwide</li>
                <li>Get an average of 42¢ per gallon off via the AtoB App</li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter className="items-stretch">
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button className="flex-1" color="primary" onClick={onNext}>
          Accept Offer & Pay ${activationFee}
        </Button>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationConfirmOffer;
