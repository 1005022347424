import Modal, { ModalBodyContent } from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export const VirtualCardsSuccessModalBody = (): ReactElement => {
  return (
    <ModalBodyContent>
      <div className="flex flex-col items-center">
        <div className="mb-3 mt-8 text-green-600">
          <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
        </div>
        <h1 className="mb-1 text-center text-2xl font-bold">Your Virtual Cards have arrived!</h1>
        <p className="text-md mb-8 w-full text-center">
          Add them to your Apple Pay or Google Pay wallet to start using them today!
        </p>

        <img src="/images/virtual-card-wallet-add.png" alt="popup-header" className="w-full py-4" />

        <a
          className="mb-12 w-full text-center text-sm underline underline-offset-4"
          href="https://atobco.zendesk.com/hc/en-us/articles/16160404307611"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to add virtual cards to your phone
        </a>

        <div className="mb-12 flex w-full justify-center">
          <Button className="w-3/4" size="medium" onClick={() => (window.location.href = '/cards')}>
            View Cards
          </Button>
        </div>
      </div>
    </ModalBodyContent>
  );
};

export const VirtualCardsSuccessModal = (): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <Modal open={isOpen} toggle={() => setIsOpen(false)}>
      <VirtualCardsSuccessModalBody />
    </Modal>
  );
};
