import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

// TODO: can we use it as default and remove the old one?
export function ModalHeaderNEW({
  title,
  closeButton,
  headerImage,
}: {
  title: string;
  closeButton: ReactElement | false;
  className?: string;
  headerImage?: ReactElement;
}): ReactElement {
  return (
    <DialogTitle
      className={classNames(
        'mb-2 text-left text-xl font-bold leading-6 text-gray-900 sm:text-left',
        headerImage ? 'p-0' : '',
      )}
    >
      {headerImage}
      <div
        className={classNames(
          'flex items-center justify-between gap-4 pt-6',
          headerImage && 'px-6',
        )}
      >
        <div className="text-3xl leading-9 tracking-tight">{title}</div>
        {/* TODO: think about creating the default close button here - pass only the onClose function */}
        {closeButton}
      </div>
    </DialogTitle>
  );
}

export function ModalCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" className="h-5 w-5" />
    </IconButton>
  );
}

export const ModalBodyContent = ({
  children,
  overflowVisible = false,
  fullScreen = false,
}: {
  children: React.ReactNode;
  overflowVisible?: boolean;
  fullScreen?: boolean;
}): ReactElement => {
  return (
    <DialogContent
      className="px-6"
      classes={{
        root: classNames({
          'overflow-y-visible': overflowVisible,
          'overflow-y-scroll': !overflowVisible,
          'sm:h-auto': fullScreen,
          'h-full': fullScreen,
          'max-h-max': fullScreen,
          'max-h-[80vh]': !fullScreen,
        }),
      }}
    >
      {children}
    </DialogContent>
  );
};

export const ModalFooter = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <DialogActions className={classNames(className, 'flex px-6 pb-6 pt-2')}>
      {children}
    </DialogActions>
  );
};

export const ModalHeader = ({
  toggle,
  title,
  headerImage,
}: {
  toggle: () => void;
  title: string;
  headerImage?: ReactElement;
}) => (
  <DialogTitle
    className={classNames(
      'mb-2 text-left text-xl font-bold leading-6 text-gray-900 sm:text-left',
      headerImage ? 'p-0' : '',
    )}
  >
    {headerImage}
    <div
      className={classNames(
        'flex items-center gap-4',
        { 'justify-between': headerImage == undefined },
        { 'justify-end px-5 md:px-8': headerImage },
      )}
    >
      <div>{title}</div>
      <button
        data-testid="ModalHeader-Close-Button"
        type="button"
        className={classNames(
          { 'absolute right-4 top-4': headerImage },
          'bg-grey5 flex h-8 w-8 flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center rounded-full focus:outline-none',
        )}
        onClick={toggle}
      >
        <FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" />
      </button>
    </div>
  </DialogTitle>
);

type ModalProps = {
  'open': boolean;
  'toggle'?: () => void;
  'data-testid'?: string;
  'noFullScreen'?: true;
  'maxWidth'?: Breakpoint;
};

export default function Modal({
  open,
  toggle,
  children,
  'data-testid': dataTestId,
  noFullScreen,
  maxWidth,
}: PropsWithChildren<ModalProps>) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={noFullScreen ? false : fullScreen}
      data-testid={dataTestId}
    >
      {children}
    </Dialog>
  );
}
