import NavBarList from '@app/components/Navigation/NavBarList';
import { NavbarGroup } from '@app/interfaces/navbar';
import { ReactElement } from 'react';
import NavBarDesktopSection from './NavBarDesktopSection';
import NavBarLogo from './NavBarLogo';
import NavBarWrapper from './NavBarWrapper';

const DesktopNav = ({ items }: { items: NavbarGroup[] }): ReactElement => {
  return (
    <div className="hidden md:flex md:w-full md:flex-col" data-testid="desktop-navbar">
      <NavBarWrapper
        logo={<NavBarLogo />}
        list={
          <NavBarList items={items} setNavbarOpen={() => {}}>
            {(section) => (
              <NavBarDesktopSection
                section={section}
                setNavbarOpen={() => {}}
                key={`nav-group-${section.key}`}
              />
            )}
          </NavBarList>
        }
      ></NavBarWrapper>
    </div>
  );
};
export default DesktopNav;
