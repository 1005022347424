import { CustomerData } from '@app/@types/customer.types';
import { ModalOptionData, ModalOptions } from '@app/components/Modals/ModalOptions/ModalOptions';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import CustomerContext from '@app/contexts/customerContext';
import { useIssuingDetails } from '@app/hooks/useIssuingDetails';
import { AddFundsModalDataWrapper } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import logger from '@app/utils/datadog-logger';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { VirtualCardCreateModal } from '../../Modals/VirtualCardsModals/VirtualCardsCreateModal';
import { VirtualCardsSuccessModal } from '../../Modals/VirtualCardsModals/VirtualCardsSuccessModal';

export const CardTypeSelectionModal = ({
  open,
  setOpen,
  companyName,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  companyName: string;
}): ReactElement | null => {
  const [selectedOption, setSelectedOption] = useState<'physical' | 'virtual' | null>(null);
  const [modal, setModal] = useState<
    'select' | 'physical' | 'virtual_create' | 'virtual_success' | null
  >('select');
  const toggleModal = () => setOpen(!open);
  const { customer } = useContext(CustomerContext);

  const options: ModalOptionData[] = [
    {
      id: 'physical',
      heading: 'Physical Card',
      subheading: '$25 one-time account setup fee',
      description: ['Delivered in about 10 days'],
    },
    {
      id: 'virtual',
      heading: 'Virtual Card',
      subheading: 'Free',
      description: [
        'Available to use instantly, anywhere contactless payments are accepted',
        <a
          key="virtual_card_link"
          className="underline underline-offset-4"
          href="https://atobco.zendesk.com/hc/en-us/articles/16160404307611"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to add virtual cards to your phone
        </a>,
      ],
    },
  ];

  const handleContinue = async () => {
    if (selectedOption === null) {
      return;
    } else if (selectedOption === 'physical') {
      logger.info('Virtual Cards Event', { event: 'selected_physical', customer_id: customer.id });
      setModal('physical');
    } else if (selectedOption === 'virtual') {
      logger.info('Virtual Cards Event', { event: 'selected_virtual', customer_id: customer.id });
      setModal('virtual_create');
    }
  };

  switch (modal) {
    case 'select':
      return (
        <Modal open={open} toggle={toggleModal}>
          <ModalHeader title="Get Your Cards" toggle={toggleModal} />
          <ModalBodyContent overflowVisible={true}>
            <div className="flex flex-col text-base text-gray-700">
              <div className="xs:mb-6 my-2 text-sm">
                Choose if you'd like a physical or virtual card.
              </div>
              <ModalOptions
                options={options}
                selectedOptionId={selectedOption}
                setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
              />
            </div>
          </ModalBodyContent>
          <div className="mt-5 flex w-full justify-center sm:mb-4 md:justify-end">
            <Button
              className="w-full sm:w-1/2"
              color="primary"
              disabled={selectedOption === null}
              onClick={handleContinue}
            >
              {selectedOption === null ? 'Select an Option' : 'Continue'}
            </Button>
          </div>
        </Modal>
      );

    case 'virtual_create':
      return (
        <VirtualCardCreateModal
          isOpen={modal === 'virtual_create'}
          onBack={() => setModal('select')}
          onSuccess={() => {
            logger.info('Virtual Cards Event', {
              event: 'created_virtual',
              customer_id: customer.id,
            });
            setModal('virtual_success');
          }}
          onClose={toggleModal}
        />
      );
    case 'virtual_success':
      return <VirtualCardsSuccessModal />;
    case 'physical':
      return (
        <AddFundsModalDataWrapper
          customerCompanyName={companyName}
          customerCompanyAddress={customer.company_address}
          onAddFunds={() => {
            logger.info('Virtual Cards Event', {
              event: 'created_physical',
              customer_id: customer.id,
            });
            window.location.href = '/';
          }}
          modalActive={modal === 'physical'}
          setModalActive={() => {
            modal === 'physical' && setOpen(false);
          }}
          minimumDepositAmountCents={25 * 100}
          initialAmount={25 * 100}
          initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
          scenario={'account_setup_fee'}
        />
      );
    default:
      return null;
  }
};

export const CardTypeSelection = ({
  customer,
}: {
  customer: CustomerData;
}): ReactElement | null => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  // If BYOC / KYC is incomplete, don't show the modal until afterwards.
  const { issuingOnboardingIncomplete } = useIssuingDetails();

  const showVirtualCardsModal = !(issuingOnboardingIncomplete || customer.has_cards);

  useEffect(() => {
    if (showVirtualCardsModal)
      logger.info('Virtual Cards Event', { event: 'selection_offered', customer_id: customer.id });
  }, [customer, showVirtualCardsModal]);

  if (!showVirtualCardsModal) {
    return null;
  }

  return (
    <CardTypeSelectionModal
      open={modalOpen}
      setOpen={setModalOpen}
      companyName={customer.company_name}
    />
  );
};
