import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement, ReactNode } from 'react';
import useSWR from 'swr';
import { SWRInfiniteKeyLoader } from 'swr/infinite';
import { FilterDropdownInfiniteList } from '../Filters/FilterDropdownInfiniteList';

export function AssignEntityInfiniteList<
  T extends Record<string, unknown> & { id: string | number },
>({
  entityId,
  name,
  keyFunc,
  labelField,
  singleEntityUrl,
  onChange,
  renderListItem,
}: {
  entityId: string | null;
  name: string;
  keyFunc: (s: string) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<T>>;
  labelField: keyof T;
  singleEntityUrl: string;
  onChange: (id: string | null) => void;
  renderListItem: (item: T, isSelected: boolean) => ReactNode;
}): ReactElement {
  const {
    data: entityFetchData,
    error: entityFetchError,
    isLoading: entityFetchLoading,
  } = useSWR<EndpointResponse<T>>(
    entityId ? { url: singleEntityUrl + entityId } : null,
    apiGetFetcher,
  );

  return (
    <div className="flex-[1_1_152px]">
      <div className="w-relative w-full">
        <FilterDropdownInfiniteList
          name={name}
          selectedIds={entityId ? [entityId] : []}
          onSelect={(value) => {
            onChange(value[0]);
          }}
          renderValue={() => {
            if (entityFetchError) {
              return '(error)';
            }
            if (entityFetchLoading) {
              return '(loading)';
            }

            let rawLabel = undefined;
            if (entityFetchData) {
              rawLabel = entityFetchData.data[labelField] as string;
            }
            return entityId ? rawLabel : `Assign ${name.toLowerCase()}`;
          }}
          renderListItem={renderListItem}
          keyFunc={keyFunc}
          multiselect={false}
        />
      </div>
    </div>
  );
}
