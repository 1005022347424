import {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

export interface UnlimitedToFlexMigrationBankAccountConnectedProps {
  connectionStatus: boolean;
  bankName?: string;
  accountLast4?: string;
  balanceRequirement: number;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationBankAccountConnected = ({
  connectionStatus,
  bankName,
  accountLast4,
  balanceRequirement,
  onNext,
  onBack,
  onClose,
}: UnlimitedToFlexMigrationBankAccountConnectedProps): ReactElement | null => {
  return (
    <>
      <ModalHeader title="Connected Bank Account" toggle={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="xs:mb-8 my-2">
          {connectionStatus
            ? 'Your bank account has been successfully connected.'
            : 'There were issues connecting your bank account.'}
        </div>

        <div
          className={classNames(
            connectionStatus ? 'border-green-600 bg-green-50' : 'border-red-600 bg-red-50',
            'mb-4 flex w-full rounded-md border p-3 text-left',
          )}
        >
          <div className="flex w-3/4 items-center md:flex-col md:items-start">
            <div className="mr-2 text-base font-semibold">{bankName}</div>
            <div className="mt-1 text-sm text-black">{'••••' + accountLast4}</div>
          </div>
          <div className="flex w-1/2 items-center justify-end">
            {connectionStatus ? (
              <FontAwesomeIcon icon={faCircleCheck} size="2x" style={{ color: '#16a34a' }} />
            ) : (
              <FontAwesomeIcon icon={faCircleXmark} size="2x" style={{ color: '#dc2626' }} />
            )}
          </div>
        </div>
        {!connectionStatus && (
          <div className="xs:mb-4 my-2 text-sm text-red-600">
            Your account either doesn't have a balance of at least ${balanceRequirement} or
            connection to your account failed. Please connect a different bank account or try again.
          </div>
        )}
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        {connectionStatus && (
          <Button className="flex-1" color="primary" onClick={onNext}>
            Continue
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationBankAccountConnected;
