import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { faBolt, faBuildingColumns, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';

export type PaymentMethodType = 'ach' | 'debit' | 'us_domestic_wire';
export type TabValue = 'external_transfer' | 'own_transfer';
export type WithdrawalState = 'initial' | 'confirmation' | 'success';

export const nameMapping: Record<PaymentMethodType, string> = {
  ach: 'Bank Transfer',
  debit: 'Instant Transfer',
  us_domestic_wire: 'Wire Transfer',
};

export const iconMapping: Record<PaymentMethodType, IconDefinition> = {
  ach: faBuildingColumns,
  debit: faBolt,
  us_domestic_wire: faBuildingColumns,
};

export const transferTimeMapping: Record<PaymentMethodType, string> = {
  ach: '1-3 biz days',
  debit: 'Instant',
  us_domestic_wire: 'Up to 1 biz day',
};

export const estimatedReceptionTimeMapping: Record<PaymentMethodType, string> = {
  ach: 'within 3 biz days',
  debit: 'shortly',
  us_domestic_wire: 'within 1 biz day',
};

export type FeeConfiguration = {
  type: PaymentMethodType;
  cents: number;
  percentage: number;
};

export type AccountType = 'bank_account' | 'debit_card';
export type RecipientType = 'external' | 'own';

export type NormalizedDestination = {
  id: string;
  name: string; // main identifier
  alternateName: string | null; // secondary identifier (nickname, expiration date, etc)
  supportedNetworks: PaymentMethodType[];
  recipientType: RecipientType;
  type: AccountType;
  lastFour: string;
  brand: string | null; // used for debit cards only. e.g. Visa, Mastercard
  recipientName: string | null; // used for external recipients only
};

export const feeString = (fee: FeeConfiguration): string => {
  const percentage = fee.percentage > 0 ? `${fee.percentage}%` : null;
  // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
  const amount = fee.cents > 0 ? `$${convertCentsToDollars({ value: fee.cents })}` : null;

  return [amount, percentage].filter(Boolean).join(' + ');
};

export const formatAmountString = (amount: string) => {
  const numberRegex = /^\d*\.?\d*$/;
  if (numberRegex.test(amount)) {
    const parts = amount.split('.');
    if (parts[1] && parts[1].length > 2) {
      // Limit to two decimal places
      return parseFloat(amount).toFixed(2);
    } else {
      return amount;
    }
  } else {
    return '';
  }
};

export const filterMethodsByTabValue = (methods: FeeConfiguration[], tabValue: TabValue) =>
  methods.filter((method) => tabValue !== 'external_transfer' || method.type !== 'debit');

export const preselectedMethodByTabValue = (
  currentlySelectedMethod: PaymentMethodType | null,
  methods: FeeConfiguration[],
  tabValue: TabValue,
) => {
  const filteredMethods = filterMethodsByTabValue(methods, tabValue);
  if (
    currentlySelectedMethod &&
    filteredMethods.some((method) => method.type === currentlySelectedMethod)
  ) {
    return currentlySelectedMethod;
  } else {
    return filteredMethods[0]?.type || null;
  }
};

export const filterDestinationsBySelectedMethod = (
  destinations: NormalizedDestination[],
  method: PaymentMethodType | null,
) => {
  if (!method) {
    return [];
  }

  if (method === 'debit') {
    return destinations.filter((destination) => destination.type === 'debit_card');
  } else {
    return destinations.filter(
      (destination) =>
        destination.type === 'bank_account' && destination.supportedNetworks.includes(method),
    );
  }
};

export const computeTotalFeeAmount = ({
  transferAmountCents,
  feeData,
}: {
  transferAmountCents: number;
  feeData: FeeConfiguration | null;
}): number => {
  if (!feeData) {
    return 0;
  }
  return Math.round(transferAmountCents * feeData.percentage) / 100 + feeData.cents;
};

export const withdrawFunds = async (
  amount: string,
  description: string,
  idempotentKey: string,
  method: FeeConfiguration,
  accountId: string,
  selectedTransferType: TabValue,
) => {
  const amountCents = Math.round(parseFloat(amount) * 100);
  await axios.post('/treasury/financial_account/transfer', {
    idempotency_key: idempotentKey,
    amount: amountCents,
    description,
    method: method.type,
    fee: computeTotalFeeAmount({ transferAmountCents: amountCents, feeData: method }),
    [selectedTransferType === 'own_transfer' ? 'payment_method_id' : 'recipient_id']: accountId,
  });
};
