import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Snackbar, SnackbarContent } from '@mui/material';
import { ReactElement, ReactNode, createContext, useCallback, useEffect, useState } from 'react';

export type ToastType = 'error' | 'info' | 'success' | 'warning';

export type ToastOptions = {
  appearance: ToastType;
  onDismiss?: () => void;
};

type MessageType = { content: ReactElement; onDismiss: (() => void) | undefined };

export type AddToast = (content: ReactElement | string, options?: ToastOptions) => void;

export const ToastContext = createContext<AddToast>((() => {}) as unknown as AddToast);

export default function ToastProvider({ children }: { children: ReactNode }): ReactElement {
  const [open, setOpen] = useState(false);
  const [incomingMessages, setIncomingMessages] = useState<MessageType[]>([]);
  const [currentMessage, setCurrentMessage] = useState<MessageType | null>(null);

  useEffect(() => {
    if (incomingMessages.length && !currentMessage) {
      setCurrentMessage(incomingMessages[0]);
      setIncomingMessages((prev) => prev.slice(1));
      setOpen(true);
    } else if (incomingMessages.length && currentMessage && open) {
      setOpen(false);
    }
  }, [incomingMessages, currentMessage, open]);

  const handleClose = useCallback(
    (_: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
      currentMessage?.onDismiss && currentMessage?.onDismiss();
    },
    [currentMessage],
  );

  const addToast = useCallback<AddToast>(
    (content: ReactElement | string, options?: ToastOptions) => {
      let node: ReactElement | null;
      if (options?.appearance) {
        switch (options.appearance) {
          case 'error':
            node = <Alert severity="error">{content}</Alert>;
            break;
          case 'info':
            node = <Alert severity="info">{content}</Alert>;
            break;
          case 'success':
            node = <Alert severity="success">{content}</Alert>;
            break;
          case 'warning':
            node = <Alert severity="warning">{content}</Alert>;
            break;
        }
      } else if (typeof content === 'string') {
        node = <SnackbarContent message={content} />;
      } else {
        node = content;
      }
      const newMsg: MessageType = { content: node, onDismiss: options?.onDismiss };
      setIncomingMessages((prev) => [...prev, newMsg]);
    },
    [],
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={5000}
        TransitionProps={{ onExited: () => setCurrentMessage(null) }}
        action={
          <button
            type="button"
            className="rounded-full bg-gray-200 text-gray-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" />
          </button>
        }
      >
        {currentMessage?.content}
      </Snackbar>
    </ToastContext.Provider>
  );
}
