import useProduct from '@app/hooks/useProduct';
import Modal, {
  ModalBodyContent,
  ModalHeaderNEW,
} from '@atob-developers/shared/src/components/Modal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ReactElement, useState } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

type Cohort = 'cohort_1_engaged' | 'cohort_1_default' | 'cohort_2' | 'cohort_3';

const BannerBodyCopy: Record<Cohort, ReactElement> = {
  cohort_1_engaged: (
    <>
      Your Engaged Carrier Card now works beyond just fuel purchases, spend on groceries, travel,
      and restaurants. Plus, you’ll earn up to <strong>3% cash back</strong>, on eligible
      transactions.
    </>
  ),
  cohort_1_default: (
    <>
      Your AtoB Fuel Card now works beyond just fuel purchases, spend on groceries, travel, and
      restaurants. Plus, you’ll earn up to <strong>3% cash back</strong>, on eligible transactions.
    </>
  ),
  cohort_2: (
    <>
      Your AtoB Fuel Card now works for all purchases, not just fuel. Plus, enjoy{' '}
      <strong>1% cash back</strong>.
    </>
  ),
  cohort_3: (
    <>
      Your AtoB Fuel Card now works for all purchases, not just fuel. Plus, enjoy{' '}
      <strong>0.5% cash back</strong>.
    </>
  ),
};

const CashbackOffer: Record<Cohort, string> = {
  cohort_1_engaged: 'up to 3%',
  cohort_1_default: 'up to 3%',
  cohort_2: '1%',
  cohort_3: '0.5%',
};

const HeaderImage: Record<Cohort, string> = {
  cohort_1_engaged: '/images/cashback_campaign_1.svg',
  cohort_1_default: '/images/cashback_campaign_1.svg',
  cohort_2: '/images/cashback_campaign_2.svg',
  cohort_3: '/images/cashback_campaign_2.svg',
};

const CashbackPromotionBanner = (): ReactElement | null => {
  const [showModal, setShowModal] = useState(false);
  const [engagedCustomer, cohort1, cohort2, cohort3] = useProduct(
    'engaged_customer',
    'nonfuel_sprint_cohort_1',
    'nonfuel_sprint_cohort_2',
    'nonfuel_sprint_cohort_3',
  );

  let activeCohort: Cohort | null = null;

  if (cohort1) {
    activeCohort = engagedCustomer ? 'cohort_1_engaged' : 'cohort_1_default';
  } else if (cohort2) {
    activeCohort = 'cohort_2';
  } else if (cohort3) {
    activeCohort = 'cohort_3';
  }

  if (!activeCohort) {
    // Hide the banner if the user doesn't have a cohort
    return null;
  }

  const dismiss = () => {
    setShowModal(false);
  };

  const closeIcon = (
    <IconButton onClick={dismiss} className="absolute right-4 top-4">
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <>
      <NewFeatureBanner
        onClick={() => {
          setShowModal(true);
        }}
        actionText="Learn More"
        bannerKey="cashback-promotion-banner"
        variant="no_title"
      >
        <div className="w-full">
          <strong>Upgrade Alert!</strong> {BannerBodyCopy[activeCohort]}
        </div>
      </NewFeatureBanner>

      <Modal open={showModal} toggle={dismiss}>
        <ModalHeaderNEW
          title={`Great news, you’re now earning ${CashbackOffer[activeCohort]} cash back on your card!`}
          closeButton={closeIcon}
          headerImage={
            <img src={HeaderImage[activeCohort]} alt="popup-header" className="w-full" />
          }
        />
        <ModalBodyContent>
          <div className="flex flex-col items-center gap-2 bg-white pb-8">
            <div className="text-md text-gray-900">
              Starting today, use your card for a variety of transactions and earn{' '}
              {CashbackOffer[activeCohort]} cash back!
              <ul className="flex flex-col gap-2 py-2">
                <li>
                  <strong>Versatile Spending:</strong> Use your card for both business and personal
                  expenses.
                </li>
                <li>
                  <strong>Easy Redemption:</strong> Cash back will show as a credit in your wallet
                  balance at the end of each month.
                </li>
              </ul>
            </div>
          </div>
        </ModalBodyContent>
      </Modal>
    </>
  );
};

export default CashbackPromotionBanner;
