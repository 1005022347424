import {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export interface UnlimitedToFlexMigrationConfirmAddressProps {
  shippingAddress: string;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationConfirmAddress = ({
  shippingAddress,
  onNext,
  onBack,
  onClose,
}: UnlimitedToFlexMigrationConfirmAddressProps): ReactElement | null => {
  return (
    <>
      <ModalHeader title="Confirm Shipping Address" toggle={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCube} size="2x" className="mt-4 scale-75 text-black" />
            <div className="xs:mt-5 ml-2 mt-1">
              <div className="text-sm font-semibold">Your cards will be shipped to:</div>
              <div className="text-sm">{shippingAddress}</div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-col text-sm text-gray-500">
          If this address is incorrect, please contact support to get this changed.
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button className="flex-1" color="primary" onClick={onNext}>
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationConfirmAddress;
