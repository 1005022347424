import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { PartnerLogo } from '../layout/PartnerLogo';

export default function NavBarLogo({ closeButton }: { closeButton?: ReactElement }) {
  return (
    <div className="flex w-full flex-shrink-0 flex-row justify-between pb-0 pl-4 pt-6 align-middle md:py-10">
      <NavLink to={'/'}>
        <PartnerLogo darkStyle aria-hidden="true" />
      </NavLink>
      {closeButton}
    </div>
  );
}
