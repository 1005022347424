import { VehicleData } from '@app/@types/vehicle.types';
import { MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement, ChangeEventHandler, HTMLAttributes, ReactNode } from 'react';
import DefaultPrompt from '../Prompt/DefaultPrompt';
import { VehicleReducer } from './useVehicleFormReducer';

type VehicleFormProps = {
  reducer: VehicleReducer;
};

const FUEL_TYPES = [
  { id: 'diesel', value: 'diesel', label: 'Diesel' },
  { id: 'unleaded', value: 'unleaded', label: 'Regular' },
  { id: 'plus', value: 'plus', label: 'Midgrade and below' },
  { id: 'super', value: 'super', label: 'Premium and below' },
];

export default function VehicleForm({ reducer }: VehicleFormProps): ReactElement {
  const { vehicle, setVehicleProperty, setErrors } = reducer;

  return (
    // This is a good candidate to change to React.Context to avoid prop drilling, e.g. {...{ reducer }}
    <Form>
      {reducer.editing && vehicle.telematics_vehicle && (
        <div className="text-sm italic text-gray-600">
          This vehicle is synced from your telematics provider. Please update names and VINs with
          your telematics provider, and the changes will be synced over shortly.
        </div>
      )}
      {vehicle.errors?.map((error, idx) => (
        <DefaultPrompt
          key={idx}
          message={error}
          clickHandler={() => {
            const errors = vehicle.errors;
            errors.splice(idx, 1);
            setErrors(errors);
          }}
          error
        />
      ))}
      <Field
        label={
          <Label required editing={reducer.editing}>
            Name
          </Label>
        }
        value={<Value>{vehicle?.name}</Value>}
        input={
          !vehicle.telematics_vehicle && (
            <Input propName="name" {...{ reducer }} placeholder="Name" required={reducer.editing} />
          )
        }
        {...{ reducer }}
      />
      <Field
        label={<Label>VIN</Label>}
        value={<Value>{vehicle?.vin}</Value>}
        input={
          (vehicle.id === null || !!vehicle?.can_edit_vin) && (
            <Input propName="vin" {...{ reducer }} placeholder="VIN" />
          )
        }
        {...{ reducer }}
      />
      <Field
        label={<Label>Fuel Type</Label>}
        value={<Value>{capitalize(vehicle?.fuel_type || '')}</Value>}
        input={
          <Select
            value={vehicle?.fuel_type}
            fullWidth
            onChange={(e) => {
              if (e.target.value) {
                setVehicleProperty('fuel_type', e.target.value);
              }
            }}
            renderValue={(value) => {
              const item = FUEL_TYPES.find((item) => item.id === value);
              if (!item) {
                return <span className="text-grey17 font-normal">Select Fuel Type</span>;
              }
              return (
                <div className="flex w-full items-center justify-between text-gray-900">
                  {item.label}
                </div>
              );
            }}
            disabled={false}
            displayEmpty={true}
          >
            {FUEL_TYPES.map((item) => (
              <MenuItem key={item.id} value={item.id} classes={{ root: 'flex-col items-start' }}>
                <div className="flex cursor-pointer font-medium">{item.label}</div>
              </MenuItem>
            ))}
          </Select>
        }
        {...{ reducer }}
      />
      <Field
        label={<Label>Tank Capacity (Gallons)</Label>}
        value={
          <Value>
            {vehicle?.tank_capacity_gallons ? vehicle?.tank_capacity_gallons + ' gal' : null}
          </Value>
        }
        input={
          <TankCapacityInput
            propName="tank_capacity_gallons"
            {...{ reducer }}
            placeholder="Tank Capacity"
          />
        }
        {...{ reducer }}
      />
      <VehicleIntegrationField
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        integration_connections={vehicle?.integration_connections}
        {...{ reducer }}
      />
    </Form>
  );
}

function TankCapacityInput({ reducer, propName, ...props }: InputProps): ReactElement {
  const { vehicle, setVehicleProperty } = reducer;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value.replace(/[^0-9.]/g, '');
    setVehicleProperty(propName, value);
  };

  return (
    <TextField
      {...props}
      fullWidth
      size="small"
      onChange={onChange}
      value={(vehicle[propName] as string) || ''}
      inputProps={{ 'data-testid': `input:${propName}` }}
    />
  );
}

type FieldProps = {
  value: ReactNode;
  label?: ReactNode;
  input?: ReactNode;
  reducer: VehicleReducer;
} & HTMLAttributes<HTMLDivElement>;

function Field({ label, value, input, reducer, ...props }: FieldProps): ReactElement {
  const { editing } = reducer;

  return (
    <div {...props} className="flex flex-col gap-2">
      {label}
      {editing ? input || value : value}
    </div>
  );
}

type VehicleIntegrationFieldProps = {
  reducer: VehicleReducer;
  integration_connections: string[];
};

function VehicleIntegrationField({
  reducer,
  integration_connections,
}: VehicleIntegrationFieldProps): ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (!integration_connections || integration_connections.length === 0) return null;

  return (
    <Field
      label={<Label>Synced</Label>}
      value={
        <Value>
          {integration_connections.map((connectionName: string) => (
            <div key={connectionName}>{connectionName}</div>
          ))}
        </Value>
      }
      {...{ reducer }}
      input={null}
    />
  );
}

function Form({ children, ...props }: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div className="flex flex-col gap-8" {...props}>
      {children}
    </div>
  );
}

type InputProps = {
  reducer: VehicleReducer;
  propName: keyof VehicleData;
} & TextFieldProps;

function Input({ reducer, propName, ...props }: InputProps): ReactElement {
  const { vehicle, setVehicleProperty } = reducer;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setVehicleProperty(propName, value);
  };

  return (
    <TextField
      {...props}
      fullWidth
      size="small"
      onChange={onChange}
      value={(vehicle[propName] as string) || ''}
      type="text"
      inputProps={{ 'data-testid': `input:${propName}` }}
    />
  );
}

type LabelProps = {
  required?: boolean;
  editing?: boolean;
} & HTMLAttributes<HTMLDivElement>;

function Label({ required, editing, children }: LabelProps): ReactElement {
  return (
    <div className="text-sm font-medium text-gray-700">
      {children}
      {!!required && !!editing && <span className="text-red-500"> *</span>}
    </div>
  );
}

function Value({ children }: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <div className="text-sm font-medium">{children || '-'}</div>;
}
