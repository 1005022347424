import { DeviceData } from '@app/@types/device.types';
import { PhoneFormat } from '@atob-developers/shared/src/utils/formatters';
import { MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import { ReactElement, ChangeEventHandler, HTMLAttributes, ReactNode } from 'react';
import InputMask from 'react-input-mask';
import AssignDriver from '../AssignEntity/AssignDriver';
import AssignVehicle from '../AssignEntity/AssignVehicle';
import DefaultPrompt from '../Prompt/DefaultPrompt';
import { DeviceReducer } from './useDeviceFormReducer';

type FieldProps = {
  value: ReactNode;
  label?: ReactNode;
  input?: ReactNode;
  reducer: DeviceReducer;
} & HTMLAttributes<HTMLDivElement>;

function Field({ label, value, input, reducer, ...props }: FieldProps): ReactElement {
  const { editing } = reducer;

  return (
    <div {...props} className="flex flex-col gap-2">
      {label}
      {editing ? input || value : value}
    </div>
  );
}

type LabelProps = {
  required?: boolean;
  editing?: boolean;
} & HTMLAttributes<HTMLDivElement>;

function Label({ required, editing, children }: LabelProps): ReactElement {
  return (
    <div className="text-sm font-medium text-gray-700">
      {children}
      {!!required && !!editing && <span className="text-red-500"> *</span>}
    </div>
  );
}

function Value({ children }: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <div className="text-sm font-medium">{children || '-'}</div>;
}

type InputProps = {
  reducer: DeviceReducer;
  propName: keyof DeviceData;
} & TextFieldProps;

function Input({ reducer, propName, ...props }: InputProps): ReactElement {
  const { device, setDeviceProperty } = reducer;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setDeviceProperty(propName, value);
  };

  return (
    <TextField
      {...props}
      fullWidth
      size="small"
      onChange={onChange}
      value={(device[propName] as string) || ''}
      type="text"
      inputProps={{ 'data-testid': `input:${propName}` }}
    />
  );
}

function PhoneInput({ reducer, propName, ...props }: InputProps): ReactElement {
  const { device, setDeviceProperty } = reducer;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setDeviceProperty(propName, value);
  };

  return (
    <InputMask mask="(999) 999-9999" value={(device[propName] as string) || ''} onChange={onChange}>
      <TextField
        {...props}
        fullWidth
        size="small"
        type="text"
        inputProps={{ 'data-testid': `input:${propName}` }}
      />
    </InputMask>
  );
}

const ASSIGNEE_ITEMS = [
  { id: '', value: '', label: 'None' },
  { id: 'Driver', value: 'Driver', label: 'Driver' },
  { id: 'Vehicle', value: 'Vehicle', label: 'Vehicle' },
];

type DeviceFormProps = {
  reducer: DeviceReducer;
};

export default function DeviceForm({ reducer }: DeviceFormProps): ReactElement {
  const { device, setErrors, setDeviceProperty } = reducer;

  return (
    <div className="flex flex-col gap-8">
      {device.errors?.map((error, idx) => (
        <DefaultPrompt
          key={idx}
          message={error}
          clickHandler={() => {
            const errors = device.errors;
            errors.splice(idx, 1);
            setErrors(errors);
          }}
          error
        />
      ))}
      <Field
        label={
          /* !editing && */ <Label required editing={reducer.editing}>
            Name
          </Label>
        }
        value={<Value>{device?.name}</Value>}
        input={
          <Input propName="name" {...{ reducer }} placeholder="Name" required={reducer.editing} />
        }
        {...{ reducer }}
      />
      <Field
        label={
          /* !editing && */ <Label required editing={reducer.editing}>
            Phone Number
          </Label>
        }
        value={<Value>{PhoneFormat({ value: device?.phone_number || '' })}</Value>}
        input={
          <PhoneInput
            propName="phone_number"
            {...{ reducer }}
            placeholder="Phone number"
            required={reducer.editing}
          />
        }
        {...{ reducer }}
      />
      <Field
        label={
          /* !editing && */ <Label required editing={reducer.editing}>
            Assignment type
          </Label>
        }
        value={<Value>{device?.assignee_type}</Value>}
        input={
          <Select
            value={device?.assignee_type}
            sx={{ width: '100%' }}
            onChange={(e) => {
              if (e.target.value) {
                setDeviceProperty('assignee_type', e.target.value);
                setDeviceProperty('assignee_id', null);
              }
            }}
            renderValue={(value) => {
              const item = ASSIGNEE_ITEMS.find((item) => item.id === value);
              if (!item) {
                return <span className="text-grey17 font-normal">Select Assignment Type</span>;
              }
              return (
                <div className="flex w-full items-center justify-between text-gray-900">
                  {item.label}
                </div>
              );
            }}
            disabled={false}
            displayEmpty={true}
          >
            {ASSIGNEE_ITEMS.map((item) => (
              <MenuItem key={item.id} value={item.id} classes={{ root: 'flex-col items-start' }}>
                <div className="flex cursor-pointer font-medium">{item.label}</div>
              </MenuItem>
            ))}
          </Select>
        }
        {...{ reducer }}
      />
      {device?.assignee_type && (
        <Field
          label={
            <Label required editing={reducer.editing}>
              Assigned to
            </Label>
          }
          value={<Value>{device?.assignee_name}</Value>}
          input={
            <>
              {device?.assignee_type === 'Driver' && (
                <AssignDriver
                  driverId={device?.assignee_id || null}
                  onChange={(driverId) => driverId && setDeviceProperty('assignee_id', driverId)}
                />
              )}
              {device?.assignee_type === 'Vehicle' && (
                <AssignVehicle
                  vehicleId={device?.assignee_id || null}
                  onChange={(vehicleId) => vehicleId && setDeviceProperty('assignee_id', vehicleId)}
                />
              )}
            </>
          }
          {...{ reducer }}
        />
      )}
    </div>
  );
}
