import { PaginationMeta } from '@app/@types/api.types';
import { ChargeEventData, PotentialSavings } from '@app/@types/charge_events.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { Cell, CellDetail } from '@app/components/TableV2/Cell';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import {
  CardColumn,
  DateAndTimeColumn,
  DriverColumn,
  MoneyColumn,
  TagColumn,
} from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { TransactionStatusBadge } from '@app/components/TransactionsCard/TransactionStatusBadge';
import TransactionsMobileCard from '@app/components/TransactionsCard/TransactionsMobileCard';
import { TransactionsFilters } from '@app/constants/transactions';
import useProduct from '@app/hooks/useProduct';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface TransactionsListProps {
  isLoading: boolean;
  currentTab: string;
  handlePageChangeClick?: (updater: number) => void;
  paginationMeta?: PaginationMeta;
  cardSecurityEnabled?: boolean;
  rows: ChargeEventData[];
  pageNavPrefix: string;
  meta?: PaginationMeta;
}

const DiscountAmount = ({
  discountAmount,
  potentialDiscount,
  quantity,
}: {
  discountAmount: number;
  potentialDiscount: PotentialSavings | null;
  quantity: string | null;
}): ReactElement | null => {
  if (discountAmount === 0 && potentialDiscount != null && quantity != null) {
    const quantityNumber = parseFloat(quantity);
    const totalSavings = (potentialDiscount.savings_amount_in_cents ?? 0) * quantityNumber;
    if (totalSavings > 0) {
      return (
        <div className="text-yellow2 leading-1 -mr-1 flex text-sm">
          Missed&nbsp;
          <span className="font-medium">
            {FormatCurrency({ value: totalSavings, options: { fromCents: true } })}
          </span>
        </div>
      );
    }
  }
  if (discountAmount > 0) {
    return (
      <div className="text-ds-green leading-1 flex text-sm">
        Saved {FormatCurrency({ value: discountAmount })}
      </div>
    );
  }
  return null;
};

const TransactionsList = ({
  rows,
  isLoading,
  currentTab,
  handlePageChangeClick,
  cardSecurityEnabled,
  meta,
  pageNavPrefix,
}: TransactionsListProps): ReactElement | null => {
  const [resourceTagsEnabled] = useProduct('resource_tags');
  const { showFuelPrograms } = useThemeConfiguration();

  const navigate = useNavigate();
  const columnDefinition: ColumnDefinition[] = useMemo(
    () =>
      [
        {
          field: 'authorized_at',
          ...DateAndTimeColumn,
        },
        {
          field: 'auth_approved',
          headerName: 'Status',
          canBeShown: currentTab === TransactionsFilters.ALL,
          renderCell: (params: GridRenderCellParams<boolean, ChargeEventData>) => {
            const { auth_approved, auth_status } = params.row;
            return (
              <TransactionStatusBadge auth_approved={auth_approved} auth_status={auth_status} />
            );
          },
          minWidth: 110,
        },
        {
          field: 'decline_reason',
          headerName: 'Decline Reason',
          hideable: false,
          canBeShown: currentTab === TransactionsFilters.DECLINED,
          renderCell: (params: GridRenderCellParams<string | undefined, ChargeEventData>) => {
            return <div className="overflow-break">{params.value}</div>;
          },
          minWidth: 400,
          flex: 3,
        },
        {
          field: 'gross_amount_cents',
          headerName: 'Gross',
          ...MoneyColumn,
        },
        {
          field: 'net_amount_cents',
          headerName: 'Net',
          headerAlign: 'right',
          align: 'right',
          minWidth: 150,
          canBeShown: currentTab !== TransactionsFilters.DECLINED,
          renderCell: ({ value, row }: GridRenderCellParams<number, ChargeEventData>) => {
            const { auth_status } = row;

            if (!value) {
              return auth_status === 'pending' ? (
                <div className="text-gray-400">Pending</div>
              ) : (
                <></>
              );
            }

            return (
              <div className="my-4 text-right tracking-[-0.28px]">
                <div>{FormatCurrency({ value: value })}</div>
                <DiscountAmount
                  discountAmount={row.discount_amount_cents}
                  potentialDiscount={row.potential_savings}
                  quantity={row.quantity}
                />
              </div>
            );
          },
        },
        {
          field: 'fuel_program_name',
          headerName: 'Fuel Program',
          canBeShown: showFuelPrograms,
          minWidth: 150,
        },
        {
          field: 'tag',
          headerName: 'Tag',
          canBeShown: resourceTagsEnabled,
          valueGetter: (params: GridRenderCellParams<string, ChargeEventData>) => {
            return params.row.tag?.name;
          },
          ...TagColumn,
          flex: 2,
        },
        {
          field: 'card_last4',
          headerName: 'Card',
          flex: 1,
          ...CardColumn,
        },
        {
          field: 'merchant_name',
          headerName: 'Merchant',
          minWidth: 200,
          renderCell: (params: GridRenderCellParams<string, ChargeEventData>) => {
            if (!params.value) {
              return <div className="text-gray-400">Pending</div>;
            }
            return (
              <Cell>
                {params.value}
                {params.row.location && <CellDetail>{params.row.location}</CellDetail>}
              </Cell>
            );
          },
          flex: 2,
        },
        {
          field: 'driver_name',
          headerName: 'Driver',
          ...DriverColumn,
          renderCell: (params: GridRenderCellParams<string, ChargeEventData>) => {
            const { driver_name, transactor_name } = params.row;
            const transactorMismatch = transactor_name && driver_name !== transactor_name;
            return (
              <Cell>
                {driver_name}
                {transactorMismatch && (
                  <Tooltip title={`This card was unlocked by ${transactor_name}`} arrow>
                    <div>
                      <CellDetail>
                        <FontAwesomeIcon icon={faUnlock} size="sm" className="mr-1" />
                        {transactor_name}
                      </CellDetail>
                    </div>
                  </Tooltip>
                )}
              </Cell>
            );
          },
          flex: 2,
        },
        {
          field: 'vehicle_name',
          headerName: 'Vehicle',
          minWidth: 150,
          flex: 1,
        },
        {
          field: 'product_type',
          headerName: 'Fuel Type',
          canBeShown: (
            [TransactionsFilters.ALL, TransactionsFilters.APPROVED] as string[]
          ).includes(currentTab),
          renderCell: (params: GridRenderCellParams<string, ChargeEventData>) => {
            let quantity: number | null | string =
              params.row.quantity != null ? parseFloat(params.row.quantity) : null;
            if (quantity != null) {
              quantity = Math.round(quantity * 10) / 10;

              // Careful, quantity is now a string
              quantity = quantity.toFixed(1);
            }
            return (
              <Cell>
                {params.value}

                {quantity != null && <CellDetail>{quantity} gallons</CellDetail>}
              </Cell>
            );
          },
          minWidth: 150,
          flex: 1,
        },

        {
          field: 'unlock_id',
          headerName: 'Prompted Unlock ID',
          canBeShown: cardSecurityEnabled,
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'service_prompts_odometer',
          headerName: 'Prompted Odometer',
          canBeShown: cardSecurityEnabled,
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'telematics_odometer',
          headerName: 'Telematics Odometer',
          canBeShown: cardSecurityEnabled,
          minWidth: 200,
          flex: 1,
        },
      ] as ColumnDefinition[],
    [cardSecurityEnabled, resourceTagsEnabled, currentTab, showFuelPrograms],
  );

  return (
    <ResponsiveTable
      columns={columnDefinition}
      data={rows}
      pinLeftColumns={['authorized_at']}
      paginationMeta={meta}
      loading={isLoading}
      onPageChange={handlePageChangeClick}
      onRowClick={(row) => navigate(`${pageNavPrefix}${row.id}`)}
      mobileItemRenderer={TransactionsMobileCard}
    />
  );
};

export default TransactionsList;
