import { EndpointResponse } from '@app/@types/api.types';
import { InputField } from '@app/components/elements';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikProps } from 'formik';
import { ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import * as Yup from 'yup';
import { CreditLimitIncreaseRequestGetBankInfo } from './CreditLimitIncreaseRequestGetBankInfo';
import { CliRequestConstraints } from './CreditLimitIncreaseRequestModal';

export const CreditLimitIncreaseRequestCreate = ({
  requestConstraints,
  onNext,
  closeModal,
}: {
  requestConstraints: CliRequestConstraints;
  onNext: () => void;
  closeModal: () => void;
}): ReactElement => {
  const { addToast } = useToasts();

  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [bankInfoProvided, setBankInfoProvided] = useState(false);
  const [requestedAmount, setRequestedAmount] = useState<number>(0);
  const [requestReason, setRequestReason] = useState<string>('');

  const validationSchema = Yup.object({
    requested_amount: Yup.number()
      .required('Please specify your requested new credit limit')
      .min(
        requestConstraints.current_credit_limit + 1,
        'Must be higher than your current credit limit',
      )
      .max(
        requestConstraints.max_credit_limit,
        'Maximum amount is ' +
          FormatCurrency({
            value: requestConstraints.max_credit_limit,
            options: { fromCents: false, precision: 0 },
          }),
      ),
    request_reason: Yup.string()
      .required("Please tell us why you're requesting a credit limit increase")
      .max(2000, 'Maximum 2000 characters'),
  });

  const handleBankInfoProvided = async () => {
    setBankInfoProvided(true);
    setShowMigrationModal(false);
    await submitRequest({ requested_amount: requestedAmount, request_reason: requestReason });
  };

  const { trigger: submitRequest, isMutating: isLoading } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    { requested_amount: number; request_reason: string }
  >(
    {
      url: '/cli_requests',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          addToast(
            'Something went wrong! Please try again or contact support if the issue persists',
            { appearance: 'error' },
          );
        }
      },
      onSuccess: () => {
        onNext();
      },
    },
  );

  const onSubmit = (values: { requested_amount: number; request_reason: string }) => {
    setRequestedAmount(values.requested_amount);
    setRequestReason(values.request_reason);
    if (requestConstraints?.need_bank_info && !bankInfoProvided) {
      setShowMigrationModal(true);
    } else {
      submitRequest({ requested_amount: requestedAmount, request_reason: requestReason });
      onNext();
    }
  };

  if (showMigrationModal) {
    return (
      <CreditLimitIncreaseRequestGetBankInfo
        onNext={() => handleBankInfoProvided()}
        onBack={() => {
          setShowMigrationModal(false);
        }}
        closeModal={closeModal}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        requested_amount: requestConstraints.current_credit_limit,
        request_reason: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(
        props: FormikProps<{
          requested_amount: number;
          request_reason: string;
        }>,
      ) => (
        <>
          <ModalHeader title="Request Credit Limit Increase" toggle={closeModal} />
          <ModalBodyContent overflowVisible={true}>
            <div className="mb-6 text-[14px]">
              <p className="mb-2">
                Let us know the total credit limit you&apos;re looking for, and why you need a
                credit limit increase.
              </p>
            </div>
            <form onSubmit={props.handleSubmit}>
              <InputField id="requested_amount" label="Total credit limit" type="number" />
              <div className="mt-2">
                <InputField id="request_reason" label="Request reason" as="textarea" />
              </div>
            </form>
          </ModalBodyContent>

          <ModalFooter>
            <Button className="flex-1" color="secondary" size="small" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              className="flex-1"
              size="small"
              loading={isLoading}
              onClick={() => props.handleSubmit()}
            >
              <span>Submit Request</span>
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};
