import useMatcher from '@app/hooks/useMatcher';
import { NavbarGroup, NavbarOption } from '@app/interfaces/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

const NavBarMobileSection = ({
  section,
  setNavbarOpen,
}: {
  section: NavbarGroup;
  setNavbarOpen: (val: boolean) => void;
}): ReactElement => {
  return (
    <div data-testid="navbar-section">
      {section.title && (
        <p className="text-grey4 ml-2 mt-3 py-2 text-xs font-semibold tracking-[0.8px] sm:pt-4 md:mt-0 lg:mt-4">
          {section?.title?.toUpperCase()}
        </p>
      )}
      {section.options.map((option: NavbarOption, idx: number) => {
        const nextOption = section.options[idx + 1] ?? null;
        return (
          <NavbarOptionLink
            option={option}
            nextOption={nextOption}
            key={`nav-option-${option.key}`}
            setNavbarOpen={setNavbarOpen}
          />
        );
      })}
    </div>
  );
};

const NavbarOptionLink = ({
  option,
  nextOption,
  setNavbarOpen,
}: {
  option: NavbarOption;
  nextOption: NavbarOption | null;
  setNavbarOpen: (val: boolean) => void;
}): ReactElement => {
  const { link, showActiveOn } = option;
  const { link: nextLink, showActiveOn: nextShowActiveOn } = nextOption || ({} as NavbarOption);

  const isActive = useMatcher(link, ...(showActiveOn ?? []));
  const isNextActive = useMatcher(nextLink ?? '', ...(nextShowActiveOn ?? []));

  const linkClasses = classNames(
    'px-3.5 py-4 group flex rounded-lg cursor-pointer items-center',
    isActive && 'bg-gray-100 text-blue1 hover:text-blue1',
    !isActive && 'text-grey2 hover:bg-gray-50 hover:text-blue1 hover:bg-gray-50',
    'text-base leading-6 font-medium',
  );

  const iconClasses = classNames(
    isActive && 'text-blue1',
    !isActive && 'text-grey2',
    'mr-4 md:mr-2.5 lg:mr-4 flex-shrink-0 h-6 w-6 my-auto',
  );

  const borderClasses = classNames(
    'border-gray-100',
    !isActive && !isNextActive && 'border-b',
    'last:border-b-0',
  );

  return (
    <div className={borderClasses}>
      <NavLink
        key={option.key}
        to={option.link || '#'}
        className={linkClasses}
        data-testid="navbar-section-item"
        onClick={() => setNavbarOpen(false)}
      >
        {option.icon && <FontAwesomeIcon icon={option.icon} size="lg" className={iconClasses} />}
        <p>{option.message}</p>
      </NavLink>
    </div>
  );
};

export default NavBarMobileSection;
