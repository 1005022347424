import { useAstraOnboardingStatusQuery } from '@app/hooks/query/useAstraOnboardingStatusQuery';
import useCustomer from '@app/hooks/useCustomer';
import { getEnvironment } from '@app/utils/environment';
import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import {
  faArrowRight,
  faBuildingColumns,
  faCheck,
  faDollarSign,
  faCreditCard,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Chip, InputAdornment, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { ConfirmationModal } from './ConfirmationModal';
import { SuccessModal } from './SuccessModal';
import {
  FeeConfiguration,
  feeString,
  iconMapping,
  nameMapping,
  NormalizedDestination,
  PaymentMethodType,
  transferTimeMapping,
  WithdrawalState,
  withdrawFunds,
} from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Astra: any;

export const TransferPanel = ({
  selectedMethod,
  selectedDestination,
  loadingMethods,
  loadingDestinations,
  availableMethods,
  availableDestinations,
  onMethodSelected,
  onDestinationSelected,
  amount,
  handleAmountChange,
  description,
  setDescription,
  withdrawalState,
  setWithdrawalState,
  reset,
  onCompletedTansfer,
}: {
  selectedMethod: PaymentMethodType | null;
  selectedDestination: string | null;
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  loadingMethods: boolean;
  loadingDestinations: boolean;
  onMethodSelected: (value: PaymentMethodType) => void;
  onDestinationSelected: (value: string) => void;
  amount: string;
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  withdrawalState: WithdrawalState;
  setWithdrawalState: (value: WithdrawalState) => void;
  reset: () => void;
  onCompletedTansfer: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { data: astraStatus } = useAstraOnboardingStatusQuery();
  const eligibleForAstra = !(astraStatus?.onboarding_status == 'not_eligible');
  const { VITE_ASTRA_CLIENT_ID } = getEnvironment();
  const customer = useCustomer();
  const { addToast } = useToasts();
  const PaymentMethodItem = ({
    name,
    selected = false,
  }: {
    name: PaymentMethodType;
    selected?: boolean;
  }) => {
    const matchingFee = availableMethods.find((method) => method.type === name);

    return (
      <div className="flex w-full items-center gap-2">
        <FontAwesomeIcon icon={iconMapping[name]} />
        <div className="flex flex-grow flex-wrap items-center gap-x-2">
          <div className="flex flex-grow gap-2">
            <div className="text-base font-medium">{nameMapping[name]}</div>
            {matchingFee && <Chip label={feeString(matchingFee)} size="small" color="grey" />}
          </div>

          <div className="text-secondary text-sm">{transferTimeMapping[name]}</div>
        </div>
        {selected && <FontAwesomeIcon icon={faCheck} />}
      </div>
    );
  };

  const LoadingPaymentMethod = () => {
    return (
      <div className="flex h-fit w-full items-center gap-2">
        <Skeleton width={16} height={16} variant="rectangular" />
        <Skeleton width={'100%'} />
      </div>
    );
  };

  const DestinationItem = ({
    destinationId,
    selected = false,
  }: {
    destinationId: string;
    selected?: boolean;
  }) => {
    const destination = availableDestinations.find((elem) => elem.id === destinationId);
    if (!destination) return null;

    return (
      <div className="flex w-full flex-grow items-center gap-2">
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-grow gap-x-2 text-base font-medium">
            {destination.name}
            <span className="text-secondary font-normal">
              {destination.alternateName && ` (${destination.alternateName})`}
            </span>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <FontAwesomeIcon
              icon={destination.type === 'debit_card' ? faCreditCard : faBuildingColumns}
            />
            <span>••••&nbsp;{destination.lastFour}</span>
          </div>
        </div>
        {selected && <FontAwesomeIcon icon={faCheck} className="text-secondary" />}
      </div>
    );
  };

  const authorizeAstra = async (code: string) => {
    // eslint-disable-next-line no-console
    console.log(`onAuth: code=${code}`);
    try {
      await axios.post('/treasury/astra/authorization', {
        authorization_code: code,
      });

      setWithdrawalState('confirmation');
    } catch (error: unknown) {
      if (error instanceof Error) {
        addToast(error.message, { appearance: 'error' });
      } else {
        // Handle the case where the error is not an instance of Error
        addToast('Something went wrong. Please try again later.', { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const collectAstraAuthorization = async (businessProfileId: string) => {
    try {
      const handler = Astra.create({
        actionType: 'COLLECT_AUTHORIZATION',
        business: true,
        businessProfileId: businessProfileId,
        bypassConnect: true,
        clientId: VITE_ASTRA_CLIENT_ID,
        phone: customer.owner_phone,
        phoneReadOnly: true,
        redirectUri: 'https://app.atob.com',
        onAuth: async (code: string) => {
          authorizeAstra(code);
        },
        onClose: () => {
          setWithdrawalState('initial');
        },
        onError: () => {
          setLoading(false);
          setWithdrawalState('initial');
          addToast('Something went wrong. Please try again later.', { appearance: 'error' });
        },
      });
      handler.open();
    } catch (e: unknown) {
      setLoading(false);
      setWithdrawalState('initial');
      addToast('Something went wrong. Please try again later.', { appearance: 'error' });
    }
  };

  const onboardAstra = async () => {
    try {
      setLoading(true);
      const result = await axios.post('/treasury/astra/onboarding');
      const { business_profile_id, onboarding_status } = result.data;

      switch (onboarding_status) {
        case 'not_eligible':
          break;
        case 'needs_authorization':
          collectAstraAuthorization(business_profile_id);
          break;
        case 'active':
          setLoading(false);
          setWithdrawalState('confirmation');
          break;
      }
    } catch (error: unknown) {
      setLoading(false);

      if (error instanceof Error) {
        addToast(error.message, { appearance: 'error' });
      } else {
        // Handle the case where the error is not an instance of Error
        addToast('Something went wrong. Please try again later.', { appearance: 'error' });
      }
    }
  };

  const submitFunds = async ({
    destination,
    selectedTransferMethod,
  }: {
    destination: NormalizedDestination;
    selectedTransferMethod: FeeConfiguration;
  }) => {
    if (withdrawalState === 'initial') {
      if (eligibleForAstra) {
        onboardAstra();
      } else {
        setWithdrawalState('confirmation');
      }
      return;
    }

    setLoading(true);

    try {
      await withdrawFunds(
        amount,
        description,
        uuid(),
        selectedTransferMethod,
        destination.id,
        destination.recipientType === 'own' ? 'own_transfer' : 'external_transfer',
      );
      onCompletedTansfer();
      setWithdrawalState('success');
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        addToast(message, { appearance: 'error' });
      } else {
        addToast(defaultError, { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const destination = availableDestinations.find((dest) => dest.id === selectedDestination);

  const selectedMethodConfiguration = availableMethods.find(
    (method) => method.type === selectedMethod,
  );

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h4 className="mb-2 text-lg font-medium">Payment method</h4>
        <Select
          value={selectedMethod}
          onChange={(e) => onMethodSelected(e.target.value as PaymentMethodType)}
          displayEmpty
          fullWidth
          disabled={loadingMethods}
          renderValue={(value) =>
            value !== null ? (
              <PaymentMethodItem name={value} />
            ) : loadingMethods ? (
              <LoadingPaymentMethod />
            ) : (
              'No Payment method present'
            )
          }
        >
          {availableMethods &&
            availableMethods.map((method) => (
              <MenuItem key={method.type} value={method.type}>
                <PaymentMethodItem name={method.type} selected={selectedMethod === method.type} />
              </MenuItem>
            ))}
        </Select>
      </div>
      <div>
        <h4 className="mb-2 text-lg font-medium">Destination Account</h4>
        <Select
          value={selectedDestination}
          onChange={(e) => onDestinationSelected(e.target.value as string)}
          fullWidth
          displayEmpty
          disabled={loadingDestinations || selectedMethod === null}
          renderValue={(value) =>
            value !== null ? (
              <DestinationItem destinationId={value} />
            ) : loadingDestinations ? (
              'Loading'
            ) : (
              'Select destination'
            )
          }
        >
          {availableDestinations.map((destination) => {
            const id = destination.id;
            return (
              <MenuItem key={id} value={id}>
                <DestinationItem destinationId={id} selected={selectedDestination === id} />
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div>
        <h4 className="mb-2 text-lg font-medium">Amount</h4>
        <div className="flex gap-2">
          <TextField
            aria-errormessage='{"type":"error","message":"Amount is required"}'
            variant="outlined"
            fullWidth
            onChange={handleAmountChange}
            value={amount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div>
        <h4 className="mb-2 flex items-center text-lg font-medium">
          Description <span className="text-secondary ml-1">(optional)</span>
        </h4>
        <div className="flex gap-2">
          <TextField
            variant="outlined"
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="Describe the reason of this transaction"
          />
        </div>
      </div>
      <LoadingButton
        color="primary"
        fullWidth
        onClick={() =>
          submitFunds({
            destination: destination!,
            selectedTransferMethod: selectedMethodConfiguration!,
          })
        }
        disabled={!selectedMethod || !selectedDestination || !amount}
        loading={loading}
        endIcon={<FontAwesomeIcon icon={faArrowRight} />}
      >
        <span>Next</span>
      </LoadingButton>
      {withdrawalState === 'confirmation' && (
        <ConfirmationModal
          open
          onClose={() => setWithdrawalState('initial')}
          destination={destination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
          onConfirm={() =>
            submitFunds({
              destination: destination!,
              selectedTransferMethod: selectedMethodConfiguration!,
            })
          }
          loading={loading}
        />
      )}
      {withdrawalState === 'success' && (
        <SuccessModal
          open
          onClose={reset}
          destination={destination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
        />
      )}
    </div>
  );
};
