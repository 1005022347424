import { EndpointResponse, Entity } from '@app/@types/api.types';
import { UserData } from '@app/@types/users.types';
import { SettingsPageEntityType } from '@app/constants/settings';
import { validateSettingsForm } from '@app/utils/validation/yup-validation';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { ReactElement, useState } from 'react';

interface AddUserPhoneModalProps {
  open: boolean;
  toggle: () => void;
  user: UserData;
}

const AddUserPhoneModal = ({ open, toggle, user }: AddUserPhoneModalProps): ReactElement => {
  const { addToast } = useToasts();
  const [values, setValues] = useState<Record<string, string | null>>({});

  const submitAddUserPhoneRequest = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const params = { user: { ...user, ...{ phone: values.phone.replace(/[() -]/g, '') } } };
    axios
      .put<EndpointResponse<Entity<UserData>>>(`/users/${user.id}`, params)
      .then(() => window.location.reload())
      .catch((error) => {
        Sentry.captureException(error, {
          user: {
            request_id: error?.response?.headers?.['x-request-id'],
            user_id: user.id,
          },
        });
        addToast("There was an error saving this user's details. Please try again later", {
          appearance: 'error',
        });
      });
  };

  const update = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await validateSettingsForm(values, SettingsPageEntityType.PHONE_NUMBER).then((err) => {
      if (Object.keys(err)?.length > 0) {
        const errMsg = Object.keys(err).reduce((prevMsg, curKey) => {
          return prevMsg + ', ' + err[curKey];
        }, '');
        addToast(errMsg);
      } else {
        submitAddUserPhoneRequest();
        toggle();
      }
    });
  };

  return (
    <Modal open={open} toggle={toggle}>
      <form onSubmit={update}>
        <ModalHeader title="Add Your Phone Number" toggle={toggle} />
        <ModalBodyContent overflowVisible={true}>
          <div>
            After enabling, you'll receive an SMS verification link on your phone. Please click on
            it to verify or you will not be able to receive SMS notifications.
          </div>
          <FormElement
            element={{
              label: 'Phone Number',
              inputType: 'tel',
              placeholder: '',
              key: 'phone',
              type: DataItemType.TEXT,
            }}
            value={values['phone']}
            handleOnChange={(value) => setValues({ phone: value })}
          />
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-col-reverse gap-3 pt-2 sm:flex-row sm:justify-end ">
            <div className="max-w-12">
              <Button color="secondary" size="small" onClick={toggle}>
                Cancel
              </Button>
            </div>
            <div className="max-w-40">
              <Button
                size="small"
                disabled={!!values[SettingsPageEntityType.PHONE_NUMBER]}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddUserPhoneModal;
