import useLocalStorage from '@app/components/popups/components/useLocalStorage';
import { useEffect, useRef } from 'react';
import { useUserEventMutation } from './mutation/useUserEventMutation';
import useUser from './useUser';

/**
 * Determines if the given banner was dismissed.
 */
export const useBannerDismissed = (
  bannerKey: string,
): {
  dismissed: boolean;
  loading: boolean;
  setDismissed: () => void;
} => {
  const [dismissedLocally, setDismissedLocally] = useLocalStorage(bannerKey, false);
  const eventName = `${bannerKey}_dismissed`;
  const { trigger } = useUserEventMutation(eventName);
  const { user, isLoading } = useUser();

  const dismissedServerSide =
    user && user.user_events && user.user_events.some((event) => event.event_name === eventName);

  const uploadRef = useRef(false);
  useEffect(() => {
    if (!isLoading && !uploadRef.current) {
      uploadRef.current = true;
      dismissedLocally && !dismissedServerSide && trigger({ eventName });
    }
  });

  const setDismissed = () => {
    setDismissedLocally(true);
    trigger({ eventName: eventName });
  };

  return {
    dismissed: dismissedLocally || !!dismissedServerSide,
    loading: isLoading,
    setDismissed,
  };
};
