import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DeviceData } from '@app/@types/device.types';
import DeviceForm from '@app/components/Devices/DeviceForm';
import { useDeviceFormReducer } from '@app/components/Devices/useDeviceFormReducer';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';
interface AddDeviceModalProps {
  open: boolean;
  toggle: () => void;
  refreshData: () => void;
}

type DeviceInfoUpdateStates = 'none' | 'error' | 'successful';

const addDeviceSuccessMessage = 'Device successfully added!';
const addDeviceErrorMessage = 'There was an error adding the device. Please try again.';

const AddDeviceInfoPrompt = ({
  addDeviceState,
  setAddDeviceState,
  message,
}: {
  addDeviceState: DeviceInfoUpdateStates;
  setAddDeviceState: (state: DeviceInfoUpdateStates) => void;
  message?: string;
}) => {
  if (addDeviceState === 'none') {
    return null;
  }

  return (
    <DefaultPrompt
      clickHandler={() => setAddDeviceState('none')}
      error={addDeviceState === 'error'}
      message={
        addDeviceState === 'error' ? message || addDeviceErrorMessage : addDeviceSuccessMessage
      }
    />
  );
};

const AddDeviceModal = ({ open, toggle, refreshData }: AddDeviceModalProps): ReactElement => {
  const reducer = useDeviceFormReducer({
    editing: true,
  });
  const { device, setDevice } = reducer;

  const [error, setError] = useState<string | null>();
  const [addDeviceState, setAddDeviceState] = useState<DeviceInfoUpdateStates>('none');

  const addDeviceHandler = () => {
    axios
      .post<EndpointResponse<Entity<DeviceData>>>('/devices', { device })
      .then(() => {
        refreshData();
        toggle();
        setDevice();
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.error;
        setError(errorMessage || 'Sorry, an unknown error occurred. Please try again later.');
        setAddDeviceState('error');
      });
  };

  const modalToggle = () => {
    setAddDeviceState('none');
    setDevice(); // Clear state of modal
    toggle();
  };

  return (
    <Modal open={open} toggle={modalToggle}>
      <ModalHeader title="Add New Device" toggle={modalToggle} />
      <ModalBodyContent>
        <AddDeviceInfoPrompt
          setAddDeviceState={setAddDeviceState}
          addDeviceState={addDeviceState}
          message={error || undefined}
        />
        <div className="mb-36 py-4">
          <DeviceForm {...{ reducer }} />
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex justify-end gap-3 ">
          <div className="max-w-12">
            <Button color="secondary" size="small" onClick={modalToggle}>
              Cancel
            </Button>
          </div>
          <div className="max-w-40">
            <Button size="small" onClick={() => addDeviceHandler()}>
              Add New Device
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddDeviceModal;
