import Checkbox from '@atob-developers/shared/src/components/Checkbox';
import {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export interface UnlimitedToFlexMigrationAcceptOfferProps {
  creditLimit: number;
  activationFee: number;
  onNext: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationAcceptOffer = ({
  creditLimit,
  activationFee,
  onNext,
  onClose,
}: UnlimitedToFlexMigrationAcceptOfferProps): ReactElement | null => {
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);

  return (
    <>
      <div className="px-2 pt-2">
        <ModalHeader title="Congrats! You’re qualified for a credit line" toggle={onClose} />
        <ModalBodyContent overflowVisible={true}>
          <div className="flex flex-col text-base text-gray-700">
            <div className="xs:mb-4 my-2">
              Upgrade from your current prepaid card to a credit line. No credit check or
              application required.
            </div>

            <div className="mb-4 font-bold">Review Your Offer:</div>

            <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
              <div className="w-full px-4 pt-4">
                <div className="flex items-center md:flex-col md:items-start">
                  <h3 className="mr-2 text-4xl font-semibold">${creditLimit}</h3>
                  <div className="mt-2 text-sm font-semibold text-black">
                    Weekly credit line. Billed weekly
                  </div>
                </div>
              </div>
              <div className="ml-4 flex flex-col items-start p-2">
                <ul className="list-disc p-1 text-sm">
                  <li>Upgrade to credit with a one-time conversion fee of ${activationFee}</li>
                  <li>
                    A credit enablement surcharge of ${0.02 * creditLimit}* will be applied to your
                    statement each week for the first two months
                  </li>
                  <li>
                    Automatically eligible for a credit line increase evaluation after 1 month**
                  </li>
                </ul>
              </div>
              <div className="my-2 mb-4 flex flex-col px-4 text-xs text-gray-400 sm:my-2">
                <div>* The surcharge amount is 2% of your credit line per week.</div>
                <div className="mt-2">
                  **Eligibility for line increases is considered after 1 month of adequate card
                  usage, good payment history and a bank connection.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="xs:mt-5 mt-1">
                <Checkbox
                  setChecked={() => {
                    setTosAccepted(true);
                  }}
                  checked={tosAccepted}
                  label="I accept the terms and conditions"
                  size="lg"
                  name="ul-to-flex-tos-accept"
                />
              </div>
            </div>
          </div>
        </ModalBodyContent>
      </div>
      <ModalFooter>
        <div className="flex w-full justify-center md:justify-end">
          <Button
            className="w-full sm:w-48"
            color="primary"
            onClick={onNext}
            disabled={tosAccepted === false}
          >
            Continue with Offer
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationAcceptOffer;
