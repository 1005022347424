import { faCheckCircle, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';

export interface ScreenProps {
  onClose: () => void;
  onContinue?: () => void;
}

export const ExportStart = ({ onClose, onContinue }: ScreenProps): ReactElement => (
  <>
    <DialogTitle>Export</DialogTitle>
    <DialogContent className="flex flex-col gap-8">
      <FiltersNotice />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" size="small">
        Close
      </Button>
      <Button size="small" onClick={onContinue}>
        Download
      </Button>
    </DialogActions>
  </>
);

export const FiltersNotice = (): ReactElement => (
  <div className="text-sm text-gray-500">
    Export data to a CSV. Any active filters from the page will automatically apply to the export.
  </div>
);

export const Downloading = (): ReactElement => (
  <DialogContent className="m-4 w-96">
    <div className="text-center">
      <CircularProgress />
      <div className="mb-2 mt-4 text-lg font-semibold">Preparing to export...</div>
      <p className="text-sm text-gray-500">
        This may take a while if there is a lot of data. Please don't close this window.
      </p>
    </div>
  </DialogContent>
);

export const Completed = ({
  downloadLink,
  onClose,
}: { downloadLink: string } & ScreenProps): ReactElement => (
  <div>
    <DialogContent className="m-4 w-96">
      <div className="text-center">
        <FontAwesomeIcon icon={faCheckCircle} className="text-6xl text-green-500" />
        <div className="mb-2 mt-4 text-lg font-semibold">Complete</div>
        <p className="text-sm text-gray-500">
          Your export should be downloading now. Didn't download?{' '}
          <a className="text-blue-500 hover:underline" href={downloadLink} download>
            Click here to download manually.
          </a>
        </p>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </div>
);

export const Error = ({ onClose }: ScreenProps): ReactElement => (
  <div>
    <DialogContent className="m-4 w-96">
      <div className="text-center">
        <FontAwesomeIcon icon={faWarning} className="text-6xl text-red-500" />
        <div className="mb-2 mt-4 text-lg font-semibold">That didn't work</div>
        <p className="text-sm text-gray-500">
          We couldn't export your data. If this keeps happening, please contact us to let us know.
        </p>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </div>
);
