import CardDesign from '@app/assets/images/svg/card_design.svg';
import { ReactivateCesOrUnlimitedParams } from '@app/components/Modals/CardModals/ReactivateCesOrUnlimitedOfferModal';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import { ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
export const ReactivatedSuccessfullyCesOrUnlimitedOffer = ({
  closeModal,
}: {
  params: ReactivateCesOrUnlimitedParams;
  closeModal: () => void;
}): ReactElement => {
  const isUF = useIsUberfreight(true);

  const handleDone = () => {
    closeModal();
  };

  return (
    <>
      <ModalHeader title="" toggle={closeModal} />
      <div className="flex flex-col items-center">
        <div className="mb-3 mt-8 text-green-600">
          <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
        </div>
        <h1 className="mb-1 text-center text-2xl font-bold">Account Reactivated</h1>
        <p className="text-md mb-2 w-full text-center">
          We have reactivated your account. Thank you for accepting the offer!
        </p>

        {!isUF && <img src={CardDesign} alt="card-design" className="w-3/4 py-8" />}

        <div className="mb-12 flex w-full justify-center">
          <Button className="w-3/4" color="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </div>
    </>
  );
};
