import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewConfirmBankAccount = ({
  bankAccounts,
  onNext,
  onBack,
  toggle,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  const bankAccount = bankAccounts && bankAccounts[0];

  if (!bankAccount) return null;

  return (
    <>
      <ModalHeader title="Confirm Primary Bank Account" toggle={toggle} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-8 text-sm">
          The $75 one-time account setup fee will be automatically charged to this account.
        </div>

        <div
          className={classNames(
            'border-green-600 bg-green-50',
            'mb-4 flex w-full rounded-md border p-3 text-left',
          )}
        >
          <div className="flex w-3/4 items-center md:flex-col md:items-start">
            <div className="mr-2 text-base font-semibold">{bankAccount.name}</div>
            <div className="mt-1 text-sm text-black">{'••••' + bankAccount.mask}</div>
          </div>
          <div className="flex w-1/2 items-center justify-end">
            <FontAwesomeIcon icon={faCircleCheck} size="2x" style={{ color: '#16a34a' }} />
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-between gap-2">
          <Button className="flex-1" size="medium" color="secondary" onClick={onBack}>
            Back
          </Button>
          <Button className="flex-1" size="medium" onClick={onNext}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
