import { PaginationMeta } from '@app/@types/api.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { DateAndTimeColumn, monospacedStyle } from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { Button, Chip } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { TransactionStatus, WalletTransaction } from '../Overview';

const StatusPill = ({ status }: { status: TransactionStatus }) => {
  if (status === 'success') {
    return <Chip label="Complete" color="success" />;
  }

  if (status === 'pending') {
    return <Chip label="Pending" color="warning" />;
  }

  if (status === 'failed') {
    return <Chip label="Failed" color="error" />;
  }

  if (status === 'canceled') {
    return <Chip label="Canceled" color="default" />;
  }

  if (status === 'returned') {
    return <Chip label="Returned" color="default" />;
  }

  return null;
};

const MobileRow = ({ row }: { row: WalletTransaction }) => {
  const { amount, created_timestamp, direction, status, description, receipt_url, fee } = row;
  const isOutbound = direction === 'outbound';

  return (
    <MobileListItem>
      <div className="mb-3 flex flex-wrap items-center justify-between ">
        <div className="pr-3 text-sm font-medium text-black">
          {isOutbound ? `-${amount}` : amount}
        </div>
        <div className="text-black0 pl-2 pr-3 text-sm font-medium">
          {dayjs.unix(created_timestamp).format('MMMM DD, YYYY')}
        </div>
        <StatusPill status={status} />
      </div>
      <div className="flex w-full justify-between">
        <div className="mb-2 flex w-3/4 items-center justify-between">
          <div>
            <div className="text-sm font-normal text-gray-700">Description</div>
            <span className="text-black0 text-sm font-[500]">{description}</span>
          </div>
        </div>
        {fee && (
          <div className="mb-2 flex w-1/4 items-center justify-between">
            <div>
              <div className="text-sm font-normal text-gray-700">Fees</div>
              <span className="text-black0 text-sm font-[500]">{fee}</span>
            </div>
          </div>
        )}
      </div>
      {receipt_url && (
        <div className="mb-2 flex items-center justify-between">
          <div>
            <div className="text-sm font-normal text-gray-700">Statement</div>
            <a
              href={receipt_url}
              className="font -bold text-sm text-blue-600"
              target="_blank"
              rel="noreferrer"
            >
              Receipt
            </a>
          </div>
        </div>
      )}
    </MobileListItem>
  );
};

interface WalletTransactionsTableProps {
  transactionsPage: WalletTransaction[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
}

export default function WalletTransactionsTable({
  transactionsPage,
  metadata,
  onPageIndexChange,
}: WalletTransactionsTableProps): ReactElement {
  const columns = [
    {
      field: 'created_timestamp',
      headerName: 'Date',
      valueGetter: ({ row }) => dayjs.unix(row.created_timestamp),
      ...DateAndTimeColumn,
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Reference',
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) => (
        <div
          style={monospacedStyle}
          className={classNames('font-medium', row.direction === 'inbound' && 'text-green-600')}
        >
          {row.direction === 'outbound' ? '-' : '+'}
          {row.amount}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 2,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
    },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {row.fee && <div>Fee: {row.fee}</div>}
          {row.receipt_url && (
            <a href={row.receipt_url} target="_blank" rel="noopener noreferrer">
              <Button>Open Receipt</Button>
            </a>
          )}
        </div>
      ),
    },
  ] as ColumnDefinition[];

  return (
    <RoundedCard breakpoint="md">
      <ResponsiveTable
        mobileItemRenderer={MobileRow}
        columns={columns}
        data={transactionsPage}
        paginationMeta={metadata}
        onPageChange={onPageIndexChange}
        loading={false}
      />
    </RoundedCard>
  );
}
