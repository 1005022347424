import { ReactElement, ReactNode } from 'react';

export default function NavBarWrapper({
  logo,
  list,
}: {
  logo: ReactNode;
  list: ReactNode;
}): ReactElement {
  return (
    <div
      className="flex h-0 flex-1 flex-col items-center px-2 pb-4 lg:px-4"
      data-testid="navbar-list"
    >
      {logo}
      {list}
    </div>
  );
}
