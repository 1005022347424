import AutoTopUpSidebar from '@app/components/Prepaid/AutoTopUps/AutoTopUpSidebar';
import { useAutoTopUpData } from '@app/components/Prepaid/AutoTopUps/use-auto-topup-data';
import Skeleton from '@app/components/layout/Skeleton';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountInfo from './WalletOverview/AccountInfo';
import Cashback from './WalletOverview/Cashback';
import { SecurityDepositDetails } from './WalletOverview/SecurityDepositDetails';
import AddFunds from './WalletOverview/TransferFunds/AddFunds';
import WithdrawFundsSidebar from './WalletOverview/TransferFunds/WithdrawFundsSidebar';
import { WithdrawFundsSidebarNew } from './WalletOverview/TransferFunds/WithdrawFundsV2/WithdrawFundsSidebarNew';
import WalletBalance from './WalletOverview/WalletBalance';
import type { AddressType } from '@app/@types/customer.types';

const DesktopWalletOverview = ({
  accountNumber,
  routingNumber,
  balance,
  cashback,
  onAddFunds,
  customerCompanyName,
  customerCompanyAddress,
  isTrusted,
  status,
  openWithdrawSidebar,
}: StatusProps & { openWithdrawSidebar: () => void }) => {
  const canModifyFunds = status === 'enrolled' && balance !== null;
  const { autoTopUp, hasAutoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } =
    useAutoTopUpData();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(false);
  const allowedToFund = useIsAllowedToFund();

  const [hasSecurityDeposit] = useProduct('security_deposits');

  return (
    <div className="flex w-full flex-col ">
      <div className="flex justify-between gap-x-6 p-5 pb-3">
        <div className="mr-3 flex w-full">
          <div className="mr-4">
            <WalletBalance balance={balance} />
          </div>
          {cashback && (
            <div className="ml-4">
              <Cashback cashback={cashback} />
            </div>
          )}
        </div>
        {hasSecurityDeposit && <SecurityDepositDetails />}
        {isTrusted && <AccountInfo routingNumber={routingNumber} accountNumber={accountNumber} />}
      </div>
      <div className="border border-gray-100"></div>
      <div className="flex items-center justify-between p-5">
        {canModifyFunds && (
          <div className="flex">
            <div className="mr-3 justify-self-stretch md:min-w-[120px]">
              <AddFunds
                onAddFunds={onAddFunds}
                accountNumber={accountNumber}
                routingNumber={routingNumber}
                customerCompanyName={customerCompanyName}
                customerCompanyAddress={customerCompanyAddress}
                buttonProps={{
                  primary: true,
                }}
              />
            </div>
            <div className="mr-3 justify-self-stretch md:min-w-[140px]">
              <Button disabled={!allowedToFund} onClick={() => setAutoTopUpSidebarOpen(true)}>{`${
                hasAutoTopUp ? 'Edit' : 'Enable'
              } Auto-Deposit`}</Button>
            </div>
            {isTrusted && (
              <div className="justify-self-stretch md:min-w-[120px]">
                <Button fullWidth={true} onClick={openWithdrawSidebar}>
                  Transfer <span className="hidden md:inline">Funds</span>
                </Button>
              </div>
            )}
          </div>
        )}
        {isTrusted && (
          <Link to="/wallet/recipients">
            <div className="flex cursor-pointer items-center text-[16px] text-blue-700">
              <FontAwesomeIcon icon={faUserGroup} className="mr-2" /> <span>Manage recipients</span>
            </div>
          </Link>
        )}
        <AutoTopUpSidebar
          autoTopUp={autoTopUp}
          isLoading={isLoading}
          autoTopUpError={autoTopUpError}
          refreshAutoTopUp={refreshAutoTopUp}
          isOpen={autoTopUpSidebarOpen}
          toggle={() => {
            setAutoTopUpSidebarOpen(false);
            setTimeout(() => {
              refreshAutoTopUp();
            }, 1000);
          }}
        />
      </div>
    </div>
  );
};

const MobileWalletOverview = ({
  accountNumber,
  routingNumber,
  balance,
  cashback,
  onAddFunds,
  customerCompanyName,
  customerCompanyAddress,
  isTrusted,
  status,
  openWithdrawSidebar,
}: StatusProps & { openWithdrawSidebar: () => void }) => {
  const canModifyFunds = status === 'enrolled';
  const { autoTopUp, hasAutoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } =
    useAutoTopUpData();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(false);
  const [hasSecurityDeposit] = useProduct('security_deposits');

  const buttonText = (hasAutoTopUp ? 'Edit' : 'Enable') + ' Auto-Deposit';
  const menuItem = useMemo(
    () => ({
      value: buttonText,
      onClick: () => setAutoTopUpSidebarOpen(true),
      disabled: () => false,
      order: 2,
    }),
    [buttonText],
  );
  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <>
      <div className="flex w-full flex-col gap-y-2 p-5 pb-3">
        <WalletBalance balance={balance} />
        {cashback && (
          <div className="my-2">
            <Cashback cashback={cashback} />
          </div>
        )}
        {hasSecurityDeposit && <SecurityDepositDetails />}
        {canModifyFunds && (
          <div className="xs:flex-row mb-4 w-full flex-col items-center">
            <div className="xs:mr-3 mt-4 w-full">
              <AddFunds
                onAddFunds={onAddFunds}
                accountNumber={accountNumber}
                routingNumber={routingNumber}
                customerCompanyName={customerCompanyName}
                customerCompanyAddress={customerCompanyAddress}
                buttonProps={{
                  primary: true,
                  fullWidth: true,
                }}
              />
            </div>
            <div className="xs:mr-3 mt-4 w-full">
              <Button fullWidth={true} onClick={() => setAutoTopUpSidebarOpen(true)}>
                {buttonText}
              </Button>
            </div>
            {isTrusted && (
              <div className="mt-4 w-full">
                <Button fullWidth={true} onClick={openWithdrawSidebar}>
                  Transfer <span className="hidden md:ml-1 md:inline">Funds</span>
                </Button>
              </div>
            )}
          </div>
        )}
        {isTrusted && (
          <Link to="/wallet/recipients">
            <div className="flex w-full cursor-pointer items-center justify-center text-sm text-blue-600">
              <FontAwesomeIcon icon={faUserGroup} className="mr-2" /> <span>Manage recipients</span>
            </div>
          </Link>
        )}
        {isTrusted && <AccountInfo routingNumber={routingNumber} accountNumber={accountNumber} />}
        <AutoTopUpSidebar
          autoTopUp={autoTopUp}
          isLoading={isLoading}
          autoTopUpError={autoTopUpError}
          refreshAutoTopUp={refreshAutoTopUp}
          isOpen={autoTopUpSidebarOpen}
          toggle={() => {
            setAutoTopUpSidebarOpen(false);
            setTimeout(() => {
              refreshAutoTopUp();
            }, 1000);
          }}
        />
      </div>
    </>
  );
};

type StatusProps = {
  accountNumber: string | null;
  routingNumber: string | null;
  balance: string | null;
  cashback: string | null;
  onAddFunds: () => void;
  onWithdrawFunds: () => void;
  loading?: boolean;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  isTrusted: boolean;
  status:
    | 'enrolled'
    | 'not_enrolled'
    | 'started'
    | 'pending_verification'
    | 'update_required'
    | 'suspended';
};

export default function WalletOverview(props: StatusProps): ReactElement {
  const { loading, onWithdrawFunds } = props;
  const { isMobile } = useWindowWidth();
  const [showNewTransferFunds] = useFeatureFlags('new_transfer_funds_ui');
  const renderOverview = isMobile ? (
    <MobileWalletOverview
      {...props}
      openWithdrawSidebar={() => setWithdrawFundsModalActive(true)}
    />
  ) : (
    <DesktopWalletOverview
      {...props}
      openWithdrawSidebar={() => setWithdrawFundsModalActive(true)}
    />
  );
  const [withdrawFundsModalActive, setWithdrawFundsModalActive] = useState(false);

  return (
    <div>
      <div className="rounded bg-white shadow-md">
        {loading ? <Skeleton /> : <div className="flex justify-between">{renderOverview}</div>}
      </div>
      {showNewTransferFunds ? (
        <WithdrawFundsSidebarNew
          open={withdrawFundsModalActive}
          reset={() => setWithdrawFundsModalActive(false)}
          onCompletedTansfer={onWithdrawFunds}
        />
      ) : (
        <WithdrawFundsSidebar
          open={withdrawFundsModalActive}
          reset={() => setWithdrawFundsModalActive(false)}
          onWithdrawFunds={onWithdrawFunds}
        />
      )}
    </div>
  );
}
