import NavBarList from '@app/components/Navigation/NavBarList';
import { MobileNavHeaderMenuContext } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { NavbarGroup } from '@app/interfaces/navbar';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwipeableDrawer } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, useContext } from 'react';
import NavBarLogo from './NavBarLogo';
import NavBarMobileSection from './NavBarMobileSection';
import NavBarWrapper from './NavBarWrapper';

const MobileNav = ({ items }: { items: NavbarGroup[] }): ReactElement => {
  const { mobileNavOpen, setMobileNavOpen } = useContext(MobileNavHeaderMenuContext);
  return (
    <SwipeableDrawer
      anchor="left"
      open={mobileNavOpen}
      onOpen={() => setMobileNavOpen(true)}
      classes={{
        root: classNames('inset-0 overflow-hidden z-side-drawer'),
      }}
      onClose={() => setMobileNavOpen(false)}
    >
      <div className="z-side-drawer pointer-events-auto h-full w-screen max-w-full sm:max-w-md">
        <div className="flex h-full flex-col bg-white shadow-xl">
          <NavBarWrapper
            logo={
              <NavBarLogo
                closeButton={
                  <button
                    type="button"
                    className="flex h-10 w-10 items-center justify-center rounded-full pr-4 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setMobileNavOpen(false)}
                  >
                    <FontAwesomeIcon icon={faXmark} size="2x" className="text-grey2" />
                  </button>
                }
              />
            }
            list={
              <NavBarList items={items} setNavbarOpen={setMobileNavOpen} isMobile>
                {(section) => (
                  <NavBarMobileSection
                    section={section}
                    key={`nav-group-${section.key}`}
                    setNavbarOpen={setMobileNavOpen}
                  />
                )}
              </NavBarList>
            }
          />
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileNav;
