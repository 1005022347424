import VirtualCardUse from '@app/assets/images/svg/virtual_card_use.svg';
import { ErrorNotification, Loading } from '@app/components/layout';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export const VirtualCardInfoModal = ({
  onBack,
  onNext,
  continueButtonText = 'Continue',
  onClose,
}: {
  onBack: () => void;
  onNext: () => void;
  onClose: () => void;
  continueButtonText?: string;
}): ReactElement => {
  return (
    <>
      <ModalHeader title="Using Virtual Cards" toggle={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="xs:mb-6 my-2 text-sm">
            To use virtual cards, add them to your Apple Pay or Google Pay Wallet, and use your
            phone to pay at gas stations that accept contactless payment.{' '}
            <a
              className="mb-12 w-full text-center text-sm underline underline-offset-4"
              href="https://atobco.zendesk.com/hc/en-us/articles/16160404307611"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more.
            </a>
          </div>
          <div className="flex flex-row items-center">
            <img className="h-[320px] w-full" src={VirtualCardUse} alt="popup-header" />
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" color="secondary" size="small" onClick={onBack}>
          Back
        </Button>
        <Button className="flex-1" size="small" onClick={onNext}>
          {continueButtonText}
        </Button>
      </ModalFooter>
    </>
  );
};

export const VirtualCardCreateModal = ({
  isOpen,
  onBack,
  onSuccess,
  onClose,
}: {
  isOpen: boolean;
  onBack: () => void;
  onSuccess: () => void;
  onClose: () => void;
}): ReactElement => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const orderCards = async () => {
    setHasError(false);
    setLoading(true);
    try {
      await axios.post('/cards/order_virtual_cards');
      onSuccess();
    } catch (e: unknown) {
      setHasError(true);
      if (guardAxiosError(e)) {
        logger.error('Error ordering virtual cards', e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} toggle={onClose}>
      {loading && <Loading />}
      {hasError && (
        <ErrorNotification error="There was an error ordering your cards. Please try again." />
      )}
      <VirtualCardInfoModal
        onNext={orderCards}
        onBack={onBack}
        continueButtonText={'Create Cards Now'}
        onClose={onClose}
      />
    </Modal>
  );
};
