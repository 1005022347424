import { ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const CardOrderUnavailable = ({ closeModal }: { closeModal: () => void }): ReactElement => {
  return (
    <>
      <ModalHeader title="" toggle={closeModal} />
      <div className="flex flex-col items-center">
        <div className="mb-3 mt-8 text-red-600">
          <FontAwesomeIcon className="fa-3x" icon={faCircleXmark} />
        </div>
        <h1 className="mb-1 text-center text-2xl font-bold">Card Ordering Unavailable</h1>
        <p className="text-md mb-2 w-full px-4 text-center">
          This feature is currently unavailable for you. Please contact support for more
          information.
        </p>
        <div className="mb-12 mt-12 flex w-full justify-center">
          <Button className="w-3/4" size="small" onClick={closeModal}>
            Done
          </Button>
        </div>
      </div>
    </>
  );
};
