import useWindowWidth from '@app/hooks/useWindowWidth';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

const displayNumber = (detailNumber: string | null, show: boolean, limit: number): string => {
  if (!detailNumber) return '';
  return show ? detailNumber : `${'••••'.slice(-limit)}${detailNumber.slice(-4)}`;
};

const WalletDetail = ({
  title,
  detailNumber,
  showNumber,
  setShowNumber,
  copyNumber,
  setCopyNumber,
  onCopyNumber,
  restricted,
}: {
  title: string;
  detailNumber: string | null;
  showNumber: boolean;
  setShowNumber?: (showNumber: boolean) => void;
  copyNumber?: boolean;
  setCopyNumber?: (copy: boolean) => void;
  onCopyNumber?: (number: string, setCopyNumber: (copy: boolean) => void) => void;
  restricted?: boolean;
}): ReactElement => {
  const { isMobile } = useWindowWidth();
  const limit = isMobile ? 2 : 4;
  const notAvailable = detailNumber == null;

  return (
    <div
      className={classNames(`flex w-full text-base font-normal text-gray-700 `, {
        'w-full items-start justify-start': isMobile,
        'items-center justify-between': !isMobile,
      })}
    >
      <div className="w-full">
        <span className="mr-2">
          {title}
          <span className="inline md:hidden"> #</span>{' '}
          <span className="hidden md:inline">Number:</span>
        </span>
        <span className="min-w-[130px]">
          {notAvailable ? 'Not available' : displayNumber(detailNumber, showNumber, limit)}
        </span>
      </div>
      {!notAvailable && !restricted && (
        <div className="flex">
          <span
            className={`cursor-pointer text-blue-700 ${isMobile ? 'block' : ''}`}
            onClick={() => {
              setShowNumber && setShowNumber(!showNumber);
            }}
          >
            {!copyNumber && (showNumber ? 'Hide' : 'Show')}
          </span>
          <span
            onClick={() => {
              if (!onCopyNumber || !setCopyNumber) return;
              return onCopyNumber(detailNumber, setCopyNumber);
            }}
            className={`cursor-pointer text-blue-700 ${isMobile ? 'block' : ''}`}
          >
            {copyNumber ? (
              <span className="md:ml-2">Copied to clipboard!</span>
            ) : (
              <FontAwesomeIcon icon={faCopy} className="mx-4" />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default WalletDetail;
