import DesktopNav from '@app/components/Navigation/DesktopNav';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { NavbarGroup } from '@app/interfaces/navbar';
import { ReactElement } from 'react';
import MobileNav from './MobileNav';
import { computeEnabledGroups, getNavBarItems } from './NavBarItems';

const NavBar = ({ navBarItems }: { navBarItems?: NavbarGroup[] }): ReactElement | null => {
  const { interstitials, products } = useCustomer();
  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );

  if (interstitials) {
    return null;
  }

  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;
  if (navBarItems === undefined) {
    navBarItems = getNavBarItems(products, enableStreamlinedDriverAppOnboarding);
  }

  const processedNavBarItems = computeEnabledGroups(products, navBarItems);

  return (
    <div className="z-nav-bar border-grey16 sticky top-0 bg-white md:left-0 md:min-h-screen md:w-80 md:border-r">
      <div>
        <DesktopNav items={processedNavBarItems} />
        <MobileNav items={processedNavBarItems} />
      </div>
    </div>
  );
};

export default NavBar;
