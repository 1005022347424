import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useBannerDismissed } from '@app/hooks/useBannerDismissed';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { PropsWithChildren, ReactElement } from 'react';

type StatusIconColor = 'green' | 'red';

type StatusBadge = 'successful' | 'pending' | 'failed';

const ICON_COLOR: Record<StatusIconColor, string> = {
  green: 'text-ds-green',
  red: 'text-red-700',
};

export interface StatusBannerProps {
  bannerKey: string;
  icon: IconProp;
  iconColor?: StatusIconColor;
  nonDismissable?: boolean;
  onClick?: () => void;
  actionText?: string;
  onLinkClick?: () => void;
  linkText?: string;
  badge?: StatusBadge;
}

export const StatusBanner = ({
  bannerKey,
  onClick,
  actionText,
  onLinkClick,
  linkText,
  nonDismissable = false,
  icon,
  iconColor,
  badge,
  children,
}: PropsWithChildren<StatusBannerProps>): ReactElement | null => {
  const { dismissed, loading, setDismissed } = useBannerDismissed(bannerKey);
  const { hideDismissableBanners } = useThemeConfiguration();

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div className="relative w-full rounded-md bg-white px-4 py-4 text-sm font-normal">
      <div className="flex flex-row justify-start xl:items-center">
        <div className="mr-4 flex w-11 shrink-0 justify-center align-middle">
          {icon ? (
            <FontAwesomeIcon
              icon={icon}
              className={`h-6 w-6 ${iconColor && ICON_COLOR[iconColor]}`}
            />
          ) : (
            <div className="flex h-6 rounded border border-white px-2 py-1 text-[10px] uppercase leading-[15px] text-white">
              New
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between gap-1 xl:w-full xl:flex-row xl:items-center">
          <div className="flex flex-col">
            <div className="text-left">{children}</div>
          </div>

          <div className="mt-2 flex flex-row items-center gap-4 xl:mt-0">
            {actionText && onClick && (
              <Button color="primary" size="small" onClick={onClick}>
                {actionText}
              </Button>
            )}

            {badge === 'successful' && <Chip label="Successful" color="success" />}
            {badge === 'pending' && <Chip label="Pending" color="default" />}
            {badge === 'failed' && <Chip label="Failed" color="error" />}

            {linkText && onLinkClick && (
              <Link component="button" variant="body2" onClick={() => onLinkClick()}>
                {linkText}
              </Link>
            )}

            {!nonDismissable && (
              <Link component="button" variant="body2" onClick={() => setDismissed()}>
                Dismiss
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
