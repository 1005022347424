import useProduct from '@app/hooks/useProduct';
import Modal, {
  ModalBodyContent,
  ModalHeaderNEW,
} from '@atob-developers/shared/src/components/Modal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ReactElement, useState } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

const EngagedRebateBanner = (): ReactElement | null => {
  const [showModal, setShowModal] = useState(false);
  const [engagedWalletExisting] = useProduct('engaged_wallet_existing');
  const [engagedWalletDefault] = useProduct('engaged_wallet_default');

  if (!engagedWalletExisting && !engagedWalletDefault) {
    return null;
  }

  const bannerHeadline = engagedWalletExisting
    ? 'Get 0.5% rebate on factored invoices:'
    : `Congrats: You're saving 0.5% in factoring fees.`;

  const bannerBody = engagedWalletExisting
    ? 'To enroll, transfer your factored funds into your AtoB Digital Wallet.'
    : 'This applies to all factored invoices for the next 6 months!';

  const modalDisclaimer = (
    <div className="mt-4 text-sm text-gray-900">
      Customers who factor invoices directly into their "AtoB Digital Wallet" will receive a maximum
      rebate per invoice of 0.5%. Rebate is credited to your wallet every Monday. If splitting the
      invoice between multiple funding destinations, the 0.5% rebate will be applied to the amount
      funded to the "AtoB Digital Wallet" only. Offer ends 12/31/24
    </div>
  );

  const dismiss = () => {
    setShowModal(false);
  };

  const closeIcon = (
    <IconButton onClick={dismiss} className="absolute right-4 top-4">
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <>
      <NewFeatureBanner
        onClick={() => {
          setShowModal(true);
        }}
        actionText="Learn More"
        bannerKey="engaged-onboarding-banner"
        variant="no_title"
        nonDismissable={true}
      >
        <div className="w-full">
          <strong>{bannerHeadline}</strong> {bannerBody}
        </div>
      </NewFeatureBanner>

      {engagedWalletExisting ? (
        <Modal open={showModal} toggle={dismiss}>
          <ModalHeaderNEW
            title="Prepaid cardholders get a 0.5% rebate on factored invoices"
            closeButton={closeIcon}
            headerImage={
              <img src="/images/engaged-funding.png" alt="popup-header" className="w-full" />
            }
          />
          <ModalBodyContent>
            <div className="flex flex-col gap-2 bg-white pb-8 pt-4">
              <div className="text-md text-gray-900">
                By transferring factored funds into your AtoB Digital Wallet, you’ll receive a 0.5%
                rebate on each invoice for 6 months. Plus, you’ll get express same-day funding at no
                extra cost.
              </div>
              <div className="text-md text-gray-90 font-bold">How to enroll</div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border border-black text-sm">
                    1
                  </div>
                  <div>Go to your Engaged dashboard</div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border border-black text-sm">
                    2
                  </div>
                  <div>Select an invoice</div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border border-black text-sm">
                    3
                  </div>
                  <div>Select the "AtoB Digital Wallet" check box as your funding destination</div>
                </div>
                {modalDisclaimer}
              </div>
            </div>
          </ModalBodyContent>
        </Modal>
      ) : (
        <Modal open={showModal} toggle={dismiss}>
          <ModalHeaderNEW
            title="Congrats! You're eligible for a 0.5% rebate on your factored invoices."
            closeButton={closeIcon}
            headerImage={
              <img
                src="/images/engaged-funding-opted-in.png"
                alt="popup-header"
                className="w-full"
              />
            }
          />
          <ModalBodyContent>
            <div className="flex flex-col gap-2 bg-white pb-8 pt-4">
              <div className="text-md text-gray-900">
                All factored funds are automatically deposited into your AtoB Digital Wallet, a
                secure wallet connected to your prepaid card. Plus, enjoy a 0.5% rebate on each
                invoice and express same day funding at no extra cost.
              </div>
              <div className="text-md text-gray-90 font-bold">Say hi to extra cash!</div>
              {modalDisclaimer}
            </div>
          </ModalBodyContent>
        </Modal>
      )}
    </>
  );
};

export default EngagedRebateBanner;
