import useCustomer from '@app/hooks/useCustomer';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { CurrencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewReviewDetails = ({
  toggle,
  onNext,
  onBack,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  const { weekly_credit_limit } = useCustomer();
  const formattedOfferAmount = CurrencyFormat({
    value: weekly_credit_limit,
    onlyWholeNumber: true,
  });
  return (
    <>
      <ModalHeader title="Review Detail" toggle={toggle} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-8 flex flex-col text-base text-gray-700">
          <div className="mb-8 text-sm">
            Upon accepting the offer, your credit line will be activated and your fuel cards will be
            processed for shipment. You'll be charged the one-time account setup fee within a week.
          </div>

          <div className="mb-4 font-bold">Your Offer:</div>

          <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
            <div className="w-full border-b border-gray-300 p-4">
              <div className="flex items-center md:flex-col md:items-start">
                <h3 className="mr-2 text-4xl font-semibold">{formattedOfferAmount}</h3>
                <div className="mt-2 text-sm font-semibold text-black">
                  Weekly credit line. Billed weekly.
                </div>
              </div>
            </div>
            <div className="ml-4 flex flex-col items-start p-2">
              <ul className="list-disc p-2 pb-6 text-sm">
                <li>$75 one-time account setup fee</li>
                <li>
                  30¢ per gallon Credit Enablement surcharge for the first two months. Surcharge is
                  removed after two months of on-time bill payments.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-between gap-2">
          <Button className="flex-1" color="secondary" size="medium" onClick={onBack}>
            Back
          </Button>
          <Button className="flex-1" size="medium" onClick={onNext}>
            Accept & Confirm
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
