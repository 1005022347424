import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

type DriverAppLearnMoreModalProps = {
  reset: () => void;
  onDriverInvite: () => void;
  isActive: boolean;
};

const DriverAppLearnMoreModal = ({
  reset,
  onDriverInvite,
  isActive,
}: DriverAppLearnMoreModalProps): ReactElement => {
  return (
    <Modal open={isActive} toggle={reset}>
      <ModalHeader
        headerImage={
          <div className="bg-driver-app-promo absolute right-0 top-0 h-[200px] w-full bg-cover bg-no-repeat md:h-[262px]" />
        }
        toggle={reset}
        title=""
      />
      <ModalBodyContent>
        <h2 className="pb-2 pt-[200px] text-xl font-bold md:pt-[230px]">
          Exclusive discounts on the AtoB App!
        </h2>
        <div className="flex w-full flex-col items-baseline justify-between">
          <div>
            <div className="mb-4 text-base">
              <div className="pb-2">
                Find personalized fuel discounts at an exclusive network of 35,000 stations across
                the country, including Shell, Circle K, BP, and more!*
              </div>
              <div>
                Claim the discounts in the app to save on your AtoB statement. Need help? See
                detailed instructions for your{' '}
                <a
                  className="text-atob-green underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atobco.zendesk.com/hc/en-us/articles/8815161517339-Managers-Get-Started-with-the-Driver-App"
                >
                  fleet managers
                </a>
                {' and '}
                <a
                  className="text-atob-green underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atobco.zendesk.com/hc/en-us/articles/8815695178779"
                >
                  drivers
                </a>
                .
              </div>
              <div className="pt-2 italic">
                *Personalized discounts require 3 months of account history with AtoB fuel cards.
                You can use the AtoB App at any time to view real-time prices and find the best
                deal.
              </div>
            </div>
          </div>
          <Button onClick={onDriverInvite} color="primary">
            Invite Drivers
          </Button>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};

export default DriverAppLearnMoreModal;
