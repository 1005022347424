import Modal, {
  ModalBodyContent,
  ModalCloseButton,
  ModalHeaderNEW,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, ReactNode, createContext, useMemo, useRef, useState } from 'react';

export const TelematicsConnectContext = createContext<[(arg: () => void) => void, () => void]>([
  () => {},
  () => {},
]);

export default function TelematicsConnectContextWrapper({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [showModal, setShowModal] = useState(false);
  const funcRef = useRef<(() => void) | null>(null);

  const value: [(arg: () => void) => void, () => void] = useMemo(
    () => [
      (arg) => {
        funcRef.current = arg;
        setShowModal(true);
      },
      () => {
        funcRef.current = null;
        setShowModal(false);
      },
    ],
    [],
  );
  return (
    <>
      {
        <Modal open={showModal}>
          <ModalHeaderNEW
            title={'Refresh your provider'}
            closeButton={
              <ModalCloseButton
                onClick={() => {
                  funcRef.current = null;
                  setShowModal(false);
                }}
              />
            }
          />
          <ModalBodyContent>
            <p>Sometimes we need to manually refresh your provider. Please do so here:</p>
            <Button
              size="small"
              className="mt-2"
              onClick={() => {
                if (funcRef.current != null) {
                  funcRef.current();
                }
                setShowModal(false);
              }}
            >
              Refresh
            </Button>
          </ModalBodyContent>
        </Modal>
      }
      <TelematicsConnectContext.Provider value={value}>
        {children}
      </TelematicsConnectContext.Provider>
    </>
  );
}
