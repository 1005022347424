import useProduct from '@app/hooks/useProduct';
import { NavbarGroup } from '@app/interfaces/navbar';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import AtoBPlusMarketingWidget from './AtoBPlusMarketingWidget';

const NavBarList = ({
  items,
  setNavbarOpen,
  isMobile = false,
  children,
}: {
  items: NavbarGroup[];
  setNavbarOpen: (val: boolean) => void;
  isMobile?: boolean;
  children: (val: NavbarGroup) => ReactNode;
}): ReactElement => {
  const [showAtoBPlusWidget] = useProduct('promote_atob_plus');
  return (
    <div
      className={classNames(
        'space-y-4 md:space-y-2 lg:space-y-4',
        'mt-8 w-full px-4 sm:mt-0 md:px-0',
        isMobile ? 'overflow-y-scroll' : 'divide-y-2 overflow-y-visible ',
      )}
    >
      {items.map((section) => children(section))}
      {showAtoBPlusWidget && <AtoBPlusMarketingWidget setNavbarOpen={setNavbarOpen} />}
    </div>
  );
};

export default NavBarList;
