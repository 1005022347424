import Plaid from '@app/components/Plaid';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';

interface UpdateLoginModalProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  bankName: string;
  mask: string;
  bankAccountId: string;
  handleRefreshPaymentMethods: () => void;
}
const UpdateLoginModal = ({
  isActive,
  setIsActive,
  bankName,
  mask,
  bankAccountId,
  handleRefreshPaymentMethods,
}: UpdateLoginModalProps): ReactElement => {
  const onClose = (): void => {
    setIsActive(false);
  };
  return (
    <Modal open={isActive} toggle={() => setIsActive}>
      <ModalHeader toggle={onClose} title="Update Account Login Details" />
      <ModalBodyContent>
        <div className="flex flex-col items-end">
          <p className="text-black2 mb-8 text-sm">
            {`Do you want to update your account login details for ${bankName} ending in
            ${mask}?`}
          </p>
          <div className="mb-4 w-fit">
            <Plaid
              setError={() => {}}
              buttonProps={{
                className:
                  'px-4 py-3 border border-transparent rounded-full text-sm leading-4 font-medium bg-atob-green text-white mb-5',
                primary: true,
              }}
              text="Update Bank Account"
              updateBankAccountId={bankAccountId}
              onSuccessCallback={async () => {
                await handleRefreshPaymentMethods();
                setIsActive(false);
              }}
              onLinkingCallback={() => {}}
            />
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};

export default UpdateLoginModal;
