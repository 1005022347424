import useMatcher from '@app/hooks/useMatcher';
import { NavbarGroup, NavbarOption } from '@app/interfaces/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

const NavBarDesktopSection = ({
  section,
  setNavbarOpen,
}: {
  section: NavbarGroup;
  setNavbarOpen: (val: boolean) => void;
}): ReactElement => {
  return (
    <div onClick={() => setNavbarOpen(false)} data-testid="navbar-section">
      {section.title && (
        <p className="text-grey4 ml-2 mt-3 py-2 text-sm font-semibold sm:pt-4 md:mt-0 lg:mt-4">
          {section?.title?.toUpperCase()}
        </p>
      )}
      {section.options.map((option: NavbarOption) => {
        return <NavbarOptionLink option={option} key={`nav-option-${option.key}`} />;
      })}
    </div>
  );
};

const NavbarOptionLink = ({ option }: { option: NavbarOption }): ReactElement => {
  const { link, showActiveOn } = option;

  const isActive = useMatcher(link, ...(showActiveOn ?? []));

  const linkClasses = classNames(
    'px-3.5 py-2 my-1 group flex rounded-md cursor-pointer items-center',
    isActive && 'bg-gray-100 text-blue1 hover:text-blue1',
    !isActive && 'text-grey2 hover:bg-gray-50 hover:text-blue1',
    'text-sm xl:text-base font-semibold md:font-medium lg:font-semibold',
  );

  const iconClasses = classNames(
    isActive && 'text-blue1',
    !isActive && 'text-grey2',
    'mr-4 md:mr-2.5 lg:mr-4 flex-shrink-0 h-6 w-6 my-auto',
  );

  return (
    <>
      <NavLink
        key={option.key}
        to={option.link || '#'}
        className={linkClasses}
        data-testid="navbar-section-item"
      >
        {option.icon && <FontAwesomeIcon icon={option.icon} size="lg" className={iconClasses} />}
        <p>{option.message}</p>
      </NavLink>
    </>
  );
};

export default NavBarDesktopSection;
