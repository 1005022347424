import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

const PaymentDescriptionBody = ({
  driver,
  amountToPay,
  description,
  updateDescription,
}: {
  driver: FlattenedPayrollData;
  amountToPay: number;
  description: string;
  updateDescription: (_: string) => void;
}): ReactElement => {
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= 280) {
      updateDescription(value);
    }
  };
  return (
    <div>
      <div className="mb-4 flex flex-col items-center">
        <span className="text-md mt-2 text-lg font-semibold">
          Pay {driver.first_name} {driver.last_name}
        </span>
        <span className="text-md mt-2 text-xl font-semibold">
          {FormatCurrency({ value: amountToPay, options: { fromCents: true } })}
        </span>
      </div>
      <div>
        <label htmlFor="comment" className="block text-sm font-medium">
          <div className="mt-1 flex w-full flex-col font-[400]">
            <div>Add a payment description (optional)</div>
            <textarea
              rows={3}
              name="comment"
              id="comment"
              className="border-grey7 mb-1 block w-full flex-1 resize-none rounded-md border p-1 text-black shadow-sm sm:text-sm"
              value={description}
              onChange={handleDescriptionChange}
            />
            <div className={`${description.length < 280 ? 'text-gray-400' : 'text-red-600'}`}>
              {description.length}
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};
const PaymentDescriptionFooter = ({
  resetPage,
  isContinueButtonDisabled,
  confirmDescription,
}: {
  resetPage: () => void;
  isContinueButtonDisabled: boolean;
  confirmDescription: () => void;
}): ReactElement => {
  return (
    <footer className="flex w-full justify-end">
      <div className="flex max-w-[350px] gap-2">
        <Button color="secondary" size="medium" onClick={resetPage}>
          Cancel
        </Button>
        <Button size="medium" disabled={isContinueButtonDisabled} onClick={confirmDescription}>
          Continue
        </Button>
      </div>
    </footer>
  );
};

export { PaymentDescriptionBody, PaymentDescriptionFooter };
