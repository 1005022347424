import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AtoBPlusMarketingWidget({
  setNavbarOpen,
}: {
  setNavbarOpen?: (val: boolean) => void;
}): ReactElement {
  const navigate = useNavigate();
  const onNavigateToPremium = () => {
    navigate('/premium');
    setNavbarOpen && setNavbarOpen(false);
  };

  return (
    <div className="bg-ds-cool-gray-900 bg-dot-cluster-texture relative flex w-full items-center justify-between overflow-hidden rounded-lg bg-[length:110px_110px] bg-[center_right_-65px] bg-no-repeat leading-5">
      <button
        className="flex cursor-pointer items-center justify-center gap-3 rounded-[4px] border-none px-3 py-2 text-left text-sm font-medium font-semibold text-white outline-none"
        onClick={onNavigateToPremium}
      >
        <span>
          Take your business to the next level with{' '}
          <span className="text-ds-bright-green font-bold">Premium</span>
        </span>
        <FontAwesomeIcon icon={faAngleRight} className="h-[17px] w-[10px] text-white" />
      </button>
    </div>
  );
}
