import SetupDebitCardWithVGS from '@app/components/VGSElements/SetupDebitCardWithVGS';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';

interface DebitCardModalProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  forcePrimary?: boolean;
  onSuccess?: () => void;
  payroll?: boolean;
  title?: string;
}

const DebitCardModal = ({
  isActive,
  setIsActive,
  payroll = false,
  onSuccess = () => {},
  title = 'Connect a Debit Card',
}: DebitCardModalProps): ReactElement => {
  const onClose = (): void => {
    setIsActive(false);
  };

  return (
    <Modal open={isActive} toggle={() => setIsActive}>
      <ModalHeader toggle={onClose} title={title} />
      <ModalBodyContent>
        {
          // TODO (POD3-1069): Try to use with React.lazy to avoid importing unless needed.
          <SetupDebitCardWithVGS payroll={payroll} onSuccess={onSuccess} />
        }
      </ModalBodyContent>
    </Modal>
  );
};

export default DebitCardModal;
