import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData } from '@app/@types/driver.types';
import { InputField, PhoneNumberField } from '@app/components/elements';
import Ellipsis from '@app/components/elements/Ellipsis';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import Constants from '@app/utils/constants';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { Formik, FormikProps } from 'formik';
import { ReactElement, useContext, useState } from 'react';
import * as Yup from 'yup';
import { guardAxiosError } from '../../../utils/error/guards';
import PayrollContext from '../payroll-context';

const validationSchema = Yup.object({
  email: Yup.string().nullable().email('Email address is not valid'),
});

export default function AddDriverModal({
  isActive,
  setIsActive,
}: {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}): ReactElement {
  const { addDriverToState } = useContext(PayrollContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isEmployeeTenNinetyNine, setIsEmployeeTenNinetyNine] = useState(false);
  const { addToast } = useToasts();
  const closeModal = () => {
    setError('');
    setIsSuccess(false);
    setIsActive(false);
  };

  const onSubmit = async (values: Record<string, unknown>) => {
    let driverId = null;
    const { email, phone, firstName, lastName, taxClassification } = values;
    const doesNotHaveContactFields = !email && !phone;
    if (doesNotHaveContactFields) {
      setError('Email or phone number is required');
      return;
    }
    const doesNotHaveAllValues = !firstName || !lastName || !taxClassification;
    if (doesNotHaveAllValues) {
      setError('All fields are required');
      return;
    }

    const employeeIsTenNinetyNine = taxClassification === Constants.PayrollEnums.TEN_NINETY_NINE;

    setIsEmployeeTenNinetyNine(employeeIsTenNinetyNine);

    let driverToAddToState = null;

    setIsLoading(true);

    try {
      const response = await axios.post<EndpointResponse<Entity<DriverData>>>(`/drivers`, {
        driver: {
          email,
          phone,
          first_name: firstName,
          last_name: lastName,
          payroll: {
            tax_classification: taxClassification,
          },
        },
      });
      const { data } = response;
      driverId = data.data.attributes.id.toString();
      driverToAddToState = {
        ...data.data.attributes,
        id: driverId,
      };

      if (!employeeIsTenNinetyNine) {
        setIsSuccess(true);
        setIsLoading(false);
      }
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        return;
      }

      setIsLoading(false);
      const emailError =
        e?.response?.data?.errors?.email && `Email ${e?.response?.data?.errors?.email}.`;
      const phoneError =
        e?.response?.data?.errors?.phone && `Phone ${e?.response?.data?.errors?.phone}.`;
      const errorMessage =
        emailError || phoneError || 'There was a problem saving this driver. Please try again.';
      setError(errorMessage);
    }

    try {
      const response = await axios.post(`/drivers/${driverId}/payroll_invite`);
      driverToAddToState = deserialize(response.data).data;
      addDriverToState(driverToAddToState);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e: unknown) {
      Sentry.captureException(e);
      addDriverToState(driverToAddToState);
      setIsLoading(false);
      addToast('There was a problem sending the invitation. Please try again.', {
        appearance: 'error',
      });
    }
  };

  if (isSuccess) {
    const successText = isEmployeeTenNinetyNine
      ? 'Please ask your driver to look for an email from hello@atob.us and activate by adding their debit card details.'
      : 'We have created this driver in your payroll list, but only support 1099 drivers for instant payroll at this time.';
    return (
      <Modal open={isActive} toggle={closeModal}>
        <ModalHeader title="Add Driver to Payroll" toggle={closeModal} />
        <Success title="Driver added successfully." text={successText} />
      </Modal>
    );
  }

  return (
    <div>
      <Modal open={isActive} toggle={closeModal}>
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(props: FormikProps<Record<string, unknown>>) => (
            <>
              <ModalHeader title="Add Driver to Payroll" toggle={closeModal} />
              <ModalBodyContent>
                <div>
                  {error && <ErrorNotification error={error} />}
                  <NewDriverForm formikProps={props} />
                </div>
              </ModalBodyContent>
              <ModalFooter>
                <Button disabled={isLoading} size="small" onClick={() => props.handleSubmit()}>
                  {isLoading ? (
                    <span className="flex w-[104px] justify-start">
                      Adding driver
                      <Ellipsis />
                    </span>
                  ) : (
                    'Add Driver'
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

const NewDriverForm = ({
  formikProps,
}: {
  formikProps: {
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    setFieldValue: (f: string, v: string) => void;
    values: Record<string, unknown>;
  };
}): ReactElement => {
  return (
    <form onSubmit={formikProps.handleSubmit}>
      <InputField id="firstName" label="First name" />
      <InputField id="lastName" label="Last name" />
      <InputField id="email" label="Email" />
      <PhoneNumberField id="phone" label="Phone number" />
      <h3 className="block text-base font-bold text-gray-800">Employee Type</h3>
      <FormControl>
        <RadioGroup className="flex flex-row">
          <FormControlLabel
            id="employeeType1099"
            value="1099"
            label="1099"
            onChange={() => {
              formikProps.setFieldValue(
                'taxClassification',
                Constants.PayrollEnums.TEN_NINETY_NINE,
              );
            }}
            checked={
              formikProps.values.taxClassification === Constants.PayrollEnums.TEN_NINETY_NINE
            }
            control={<Radio />}
          />
          <FormControlLabel
            id="employeeTypeW2"
            value="W2"
            label="W2"
            onChange={() => {
              formikProps.setFieldValue('taxClassification', Constants.PayrollEnums.W2);
            }}
            checked={formikProps.values.taxClassification === Constants.PayrollEnums.W2}
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
    </form>
  );
};
